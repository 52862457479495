@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* Apply fonts globally */
body, h1, h2, h3, h4, h5, h6, p, ul, li {
  font-family: 'Jost', 'Lato', sans-serif;
}

.main-container {
    overflow: hidden;

}

.client-logo img {
    /* height: 35px !important; */
    width: 27px !important;
    margin-right: 15px !important;
}

.navbar-brand {
    margin-right: auto;
}

.navbar-nav {
    margin-left: auto;
}

.main-menu {
    display: none;
}

.navbar-nav {
    display: block;
}

/* Container to hold the menu */
.mobile-menu-container {
    position: relative;
  }
  
  /* Button to toggle the menu */
  .menu-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Menu overlay - dark background */
  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    z-index: 10;
  }
  
  /* Sliding menu */
  .Mobilemobile {
    position: fixed;
    top: 0;
    right: -80%; /* Initially hidden */
    height: 100%;
    width: 80%;
    background-color: white;
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease;
    z-index: 20;
  }
  
  /* When the menu is open, slide it into view */
  .Mobilemobile.open {
    right: 0;
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  /* Content inside the menu */
  .menu-content {
    padding: 20px;
  }
  
  /* Close button */
  .close-button {
border: none;
    padding: 5px;
  }
  
  /* Menu list styling */
  .menu-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-content li {
    font-size: 1.5em;
    padding: 10px 0;
  }
  

@media (max-width: 992px) {
    .dropdown-menu1 {
        overflow: hidden;
        transition: max-height 0.3s ease;
      }
      
      .dropdown-menu1.show {
        max-height: 500px; /* Adjust according to your content size */
      }
      
    .main-menu {
        display: block;
    }

    .vil {
        width: 200px !important;
    }

    .navbar-nav {
        margin-left: 0;
        /* background-color: #040E56; */
        display: none !important;
    }

    .primary-menu-item {
        color: #000;
        font-size: 11px;
        padding: 15px;
        cursor: pointer;
        /* Main menu background */
        border-bottom: 1px solid #ddd;
        position: relative;
    }

    /* Submenu Styles */
    .dropdown-menu1 {
        display: none;
        background-color: #f5f5f5;
        /* Submenu background */
        padding: 0;
        left: 0;
        top: 100%;
        /* Position below the main menu item */
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }

    /* Submenu Visible */
    .dropdown-menu1.visible {
        display: flex;
        opacity: 1;
    }

    /* Nested Submenu Styles */
    .dropdown-menu22 {
        display: none;
        background-color: #f5f5f5;
        /* Nested submenu background */
        padding: 0;
        top: 0;
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }

    /* Nested Submenu Visible */
    .dropdown-menu22.visible {
        display: block;
        max-height: 300px;
        /* Adjust based on content */
        opacity: 1;
    }

    /* Menu Item Styles */
    .menu-item,
    .menu-item2 {
        padding: 10px 20px;
        cursor: pointer;
    }


    /* Expand Indicator */
    .submenu-indicator {
        font-size: 12px;
        margin-left: 5px;
        display: inline-block;
        transition: transform 0.3s ease;
    }

    /* Rotate Indicator */
    .primary-menu-item.active .submenu-indicator {
        transform: rotate(45deg);
    }

}


/* .header{
    display: flex;
    height: 80px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
}



.navbar {
    overflow: hidden;
}

.navbar ul {
    list-style-type: none; 
    margin: 0;
    padding: 0;
    display: flex; 
}

.navbar li {
    float: left;
}

.navbar a {
    display: block;
    color: black;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
    color: #2A2A2A;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 27.2px; 
letter-spacing: 1.2px;
}

.navbar a:hover {
    background-color: #ddd;
    color: black;
} */



/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
    --header-height: 3.5rem;

    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 0%);


    --body-font: "Montserrat Alternates", sans-serif;
    --h1-font-size: 1.5rem;
    --normal-font-size: .938rem;

    --font-regular: 400;
    --font-medium: 500;

    --z-tooltip: 10;
    --z-fixed: 100;
}

::selection {
    background-color: #05116dc8 !important;
    color: white !important;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1150px) {
    :root {
        --normal-font-size: 1rem;
    }
}

/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--white-color);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/*=============== REUSABLE CSS CLASSES ===============*/


/*=============== HEADER & NAV ===============*/
.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--white-color);
    z-index: var(--z-fixed);

}

.navbar {
    box-shadow: 0 5px 20px rgba(51, 57, 88, 0.2) !important;

}

.ri-arrow-right-up-line {
    color: black;
    /* display: none; */
}


.nav {
    position: relative;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo {
    color: var(--white-color);
    font-weight: var(--font-medium);
}

.nav__close,
.nav__toggle {
    display: flex;
    /* color: var(--white-color); */
    font-size: 1.5rem;
    cursor: pointer;
    position: relative;
    left: 130px;
}




/* Navigation for mobile devices */
@media screen and (max-width: 1150px) {
    .nav__menu {
        position: fixed;
        left: -100%;
        top: 0;
        /* background-color: var(--black-color); */
        background-color: #040E56;
        color: white;
        width: 100%;
        height: 100%;
        padding: 6rem 3.5rem 4.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: left .4s;
    }

    .nav__item {
        /* transform: translateX(-150px); */
        /* visibility: hidden; */
        transition: transform .4s ease-out, visibility .4s;
    }

    .nav__item:nth-child(1) {
        transition-delay: .1s;
    }

    .nav__item:nth-child(2) {
        transition-delay: .2s;
    }

    .nav__item:nth-child(3) {
        transition-delay: .3s;
    }

    .nav__item:nth-child(4) {
        transition-delay: .4s;
    }

    .nav__item:nth-child(5) {
        transition-delay: .5s;
    }
}

.nav__list,
.nav__social {
    display: flex;
}

.nav__list {
    flex-direction: column;
    row-gap: 3rem;
}

.nav__link {
    position: relative;
    /* color: var(--white-color); */
    font-size: var(--h1-font-size);
    font-weight: var(--font-medium);
    display: inline-flex;
    align-items: center;
    transition: opacity .4s;
    /* color:white; */

}

.nav__link i {
    font-size: 2rem;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s, visibility .4s;
}

.nav__link span {
    position: relative;
    transition: margin .4s;
}

.nav__link span::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 0;
    height: 2px;
    background-color: var(--white-color);
    transition: width .4s ease-out;
}

/* Animation link on hover */
.nav__link:hover span {
    margin-left: 2.5rem;
}

.nav__link:hover i {
    opacity: 1;
    visibility: visible;
}

.nav__link:hover span::after {
    width: 100%;
}

/* Sibling fade animation */
.nav__list:has(.nav__link:hover) .nav__link:not(:hover) {
    opacity: .4;
}

.nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.nav__social {
    column-gap: 1rem;
}

.nav__social-link {
    color: var(--black-color);
    font-size: 1.2rem;
    transition: transform .4s;
}

.nav__social-link:hover {
    transform: translateY(-.25rem);
}

/* Show menu */
.show-menu {
    left: 0;
    z-index: 1000;
}

/* Animation link when displaying menu */
.show-menu .nav__item {
    visibility: visible;
    transform: translateX(0);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (min-width: 1150px) {

    .nav {
        height: calc(var(--header-height) + 2rem);
    }

    .nav__toggle,
    .nav__close {
        display: none;
    }

    .nav__link {
        font-size: var(--normal-font-size);
    }

    .nav__link i {
        font-size: 1.5rem;
    }

    .nav__list {
        flex-direction: row;
        column-gap: 3.5rem;
    }

    .nav__menu {
        display: flex;
        align-items: center;
        column-gap: 3.5rem;
    }
}

.banner-img {
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/main.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 800px;
    display: flex;
    align-items: center;
}


.vilva_test_monials {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
}



.banner-input {
    width: 250px;
    padding: 6.5px;
}

.ban-des p {
    width: 70%;
    color: #EBEBEB;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.ban-des h3 {
    color: #FFF;
    font-family: Urbanist;
    font-family: Urbanist;
    font-size: 82px;
    font-style: normal;
    font-weight: 700;
    line-height: 85px;
    /* 103.659% */

}

.ban-btn {
    background-color: #497DFF;
    color: white !important;
    font-family: Urbanist;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 10px !important;
    border: 1px solid #2A2A2A;
    cursor: pointer;
}

.tech-img {
    height: 500px;
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Section.png);
    background-repeat: no-repeat;
    background-size: contain !important;
    background-position: center;
}

.tech-des h1 {
    color: #040E56;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    /* 116.667% */
}

.tech-des p {

    color: #9B9B9B;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}

.v {
    color: #497DFF;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
}


.welcome {
    padding: 70px;
}

.Read {
    display: flex;
    height: 41.5px;
    padding: 7px 12.31px 8.5px 13px;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    border: none !important;
    padding: 5px 10px;
    background-color: #497DFF;
    color: white;
    border-radius: 5px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.5px;
    margin-bottom: 10px;
}

.whatwedo {
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/darkblue.png);
    width: 100%;
    padding-bottom: 30px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.choose {
    padding-top: 155px !important;
}

.tick {
    display: flex;
    align-items: center;
    padding-bottom: 35px;

}

.tick ul {
    padding: 0px;
}

.fa-check {
    color: #56FFBD;
    /* font-size: 30px; */
}

li {
    list-style: none;
    font-family: Montserrat;
}

.see {
    border: none;
    width: 114.31px;
    height: 41.5px;
    top: 509px;
    left: 20px;
    padding: 7px 18.31px 8.5px 19px;
    gap: 0px;
    border-radius: 0.78px 0px 0px 0px;
    opacity: 0px;
    background: #497DFF;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 25.5px;
    text-align: center;
    color: white;
    white-space: nowrap;
}

.service-all {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
}

.service2 {
    padding-top: 120px !important;
}

.service-dif {
    width: 248.98px;
    /* height: 310px; */
    color: white;
    border-radius: 1px;
    border: 1px solid #497DFF !important;
    background: #497DFF !important;
    color: #040E56;
    font-family: Montserrat;
    padding: 41.2px 23.98px 44.59px 41px;
    margin-bottom: 50px;
}

.service {
    width: 248.98px;
    /* height: 310px; */
    color: white;
    border-radius: 1px;
    border: 1px solid #56FFBD !important;
    background: #56FFBD !important;
    color: #040E56;
    font-family: Montserrat;
    padding: 41.2px 23.98px 44.59px 41px;
    margin-bottom: 50px;

}

.laptop {
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/laptop.png);
    height: 442px;
    width: 500px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.future-con p {
    color: #9B9B9B;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px;
    /* 150% */
}

.future-con h2 {
    color: #040E56;
    font-family: Urbanist;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    /* 112% */

}

.cooldes {
    background-color: #497dff !important;
    padding: 8px 10px;
    width: fit-content;
    height: fit-content;
    font-weight: 500;
    position: absolute;
    /* right: 0; */
}

.uiux {
    background-color: #040E56;
    padding: 8px 10px;
    width: fit-content;
    height: fit-content;
    font-weight: 500;
    position: relative !important;
    bottom: -370px;
    right: -350px;
}

/* .vision-all {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    gap: 10px;
} */
.check-bottom {
    color: #fff;
    font-size: 20px;
    padding: 4px;
    border-radius: 50px;
    background-color: #497DFF;
}

.check-top {
    color: #fff;
    font-size: 20px;
    padding: 4px;
    border-radius: 50px;
    background-color: #56FFBD;
    margin-right: 5px !important;
}

.vision {
    color: #497DFF;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.6px;
    /* 120% */
}

.vision h5 {
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: left;
    color: #497DFF !important;

}

.vision p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #9B9B9B;

}

.visionn {
    padding-top: 70px;
    padding-bottom: 100px;
}

.client-bg {
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/lapbelow.png);
    height: fit-content;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px !important;
}

.client-bg-des {
    padding-left: 60px;
    padding-top: 50px;
    border-radius: 30px;


}

.client-btn {
    background-color: #497DFF;
    color: white;
    padding: 7px 15.31px 8.5px 16px;
    border: none;
    border-radius: 5px;
    margin-right: 500px;
    margin-top: 20px;
    white-space: nowrap;
    margin-bottom: 25px;
}

.client-box {
    background-color: #040E56;
    color: white !important;
    gap: 20px;
    /* height: 250px; */
    padding: 32px 11px 46.89px 32px;

}

.client-box h6 {
    color: #FFF;
    font-family: Urbanist;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    /* 103.704% */
}

.client-box p {
    color: #9b9b9b;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.mobile-img {
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/mobile.png);
    height: 575px !important;
    /* width: 500px; */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.industries-des p,
li {
    color: #9B9B9B;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    padding-top: 10px;
}

.industries-des {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.keyboard-img {
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/keyboard.png);
    height: 400px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.intresting-des p {
    color: #497DFF !important;
    text-align: center !important;
    font-family: Urbanist !important;
    font-size: 25px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    /* 100% */
}

.intresting-des h5 {
    color: #040E56;
    text-align: center;
    font-family: Urbanist;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 82px;
    /* 100% */
    padding-bottom: 40px;

}

.touch-btn {
    height: 41px;
    padding: 7px 15px 8px 15px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: #497DFF;
    border: none;
    color: white;
}

.intresting-des {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 110px;
}

.test-arrow {
    display: flex;
    justify-content: center;
    font-size: 30px !important;
    margin-top: 30px !important;
    position: relative;
    top: -50px;
    left: 630px;

}

.footer {
    background-color: #040E56 !important;
    width: 100%;
}

.footer-des h5 {
    padding-bottom: 15px;
    font-family: Blinker;
}

.footer-des {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 90px;
}

.footer-des ul {
    padding: 0px;
    margin: 0px;
}

.footer-des li {
    color: #EBEBEB;
    font-family: Roboto;
    white-space: nowrap;
}

.test-img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.reserve {
    background-color: #040E56;
    display: flex;
    padding-top: 50px;
    justify-content: center;
    align-items: center;
    color: #DBDADA;
    font-family: Roboto;
}

.testimonials {
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/testimonial.png);
    width: 100%;
    height: fit-content;
}

.tick-con i {
    border-radius: 10px;
    font-family: Font Awesome 5 Free;
    font-weight: 900;
    line-height: 20px;
    text-align: center;
    background: #497DFF;
    width: 20.32px;
    height: 20px;
    top: 1.5px;
    gap: 0px;
    opacity: 0px;
    color: white;
    font-size: 15px;
}

.test-head {
    padding-left: 250px;
    padding-top: 100px;
}

.test-head P {
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: #56FFBD;
}

.t-client {
    width: 70px;
    height: 70px;
}

.client-des p {
    color: #040E56;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

.client-des {
    background-color: white;
    border-radius: 5px;
    width: 450px;
}



.client-des {
    flex: 1;
    /* Adjust the width of each item */
    text-align: center;
    /* Center text and images */
}

.client-des img {
    display: block;
    margin: 0 auto;
}

.test-car {
    padding-top: 110px;
}

.client-details {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.clientboxmove {
    position: relative;
    bottom: 100px;
}

.header {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 5px 20px rgba(51, 57, 88, 0.2);



}

.horizontal-list {
    list-style-type: none;
    /* Removes default list styles */
    padding: 0;
    margin: 0;
    background-color: #040E56;
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/lines.png);
    height: fit-content;
    width: 100%;
    background-repeat: repeat;
    background-size: cover;
    background-position: top;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 7px;
    position: relative;

}

.horizontal-list li {
    display: inline-block;
    margin-right: 10px;
}

.mySwiper {
    /* width: 100%; */
    position: relative;
}

.testi-arrows i {
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    margin-right: 20px;
}

.testi-arrows {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
}

.nav__item span {
    color: black;
}

.vilva-foot p {
    padding-top: 30px;
}

.swiper-slide {
    background-color: white !important;
}

.testi-container {
    padding-left: 20px;
    height: 350px;
}

.testi-container p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px !important;
    text-align: left;
    color: #040E56;

}

.testi-profile {
    position: absolute;
    bottom: 20px;
}

.testi-profile div p:nth-child(1) {
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: #040E56;
}

.testi-profile div p:nth-child(2) {
    font-family: Urbanist;
    font-size: 16px;
    line-height: 24px;
    text-align: left;

}

.whatwedo-tick i {
    background-color: #56FFBD;
    border-radius: 50%;
    padding: 3px 5px;
    color: #040E56 !important;

}

.choose p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #c4c4c4;

}

.choose h1 {
    font-size: 48px;
}

.choose li {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #c4c4c4;

}

/* Remove the fade transition for offcanvas */
.modal-backdrop.show {
    opacity: 0 !important;
}



.modal-backdrop {
    z-index: 0 !important;
}

.vil {
    height: 60px !important;
    width: 200px !important;
    object-fit: contain;
}

.tech-img-des {
    background-color: #497dff !important;
    width: 200px !important;
    position: relative !important;
    top: 90px !important;
    left: 7px !important;
}

.tech-img-des p {
    font-weight: 600;
    color: white;
    padding: 5px 10px;
}

/* media query for mobile */
@media only screen and (max-width: 768px) {
    .mobile-img {
        height: 700px !important;
    }
    .uiux {
        background-color: #040E56;
        padding: 8px 10px;
        width: fit-content;
        height: fit-content;
        font-weight: 500;
        position: relative !important;
        bottom: -230px !important;
        right: -185px;
    }

    .testi-container {
        padding-left: 20px;
        height: 400px;
    }


    .laptop {
        height: 300px;
    }

    .future-con h2 {
        font-size: 26px;
    }

    .future-con p {
        font-size: 16px !important;
    }

    .whatwedo {
        background-image: none;
        background-color: #040E56;
    }

    .client-bg {
        background-image: none;
    }

    .tech-img-des {
        color: white !important;
        top: 20px !important;
        left: -7px !important;
    }

    .horizontal-list {
        background-image: none;

    }

    .clientboxmove {
        background-color: #040E56 !important;
        bottom: 0;
    }

    .tick {
        display: block;
    }

    .vil {
        width: 200px !important;
    }

    .client-box h6 {
        color: #FFF;
        font-family: Urbanist;
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 35px;
        /* 103.704% */
    }

    .horizontal-list {
        padding-left: 0px;
    }

    .horizontal-list li {
        font-size: 11px !important;

    }

    /*.client-bg {*/
    /*    background-image: url(../internal/images/lapbelow\ -\ Copy.png);*/
    /*    height: fit-content;*/
    /*    width: 100%;*/
    /*    background-repeat: no-repeat;*/
    /*    background-size: cover;*/
    /*    background-position: center;*/
    /*}*/

    .service {
        width: 100%;
        height: fit-content;
        color: white;
        border-radius: 1px;
        border: 1px solid #56FFBD;
        background: #56FFBD;
        color: #040E56;
        font-family: Montserrat;
        padding: 20px;
        margin-bottom: 50px;

    }

    .nav__item span {
        color: white;
    }

    .nav__social-link {
        color: white;
    }

    .nav__close {
        display: flex;
        color: var(--white-color);
        font-size: 1.5rem;
        cursor: pointer;
        position: relative;
        top: -750px;
        left: 285px;
    }

    .service h2 {
        font-size: 24px;
    }

    .service p {
        font-size: 9px;
    }

    .banner-img {
        background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/homemobile.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
    }

    .ban-des {
        padding-left: 40px;
    }

    .ban-des p {

        color: #EBEBEB;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

    }

    .ban-des h3 {
        color: #FFF;
        font-family: Urbanist;
        font-family: Urbanist;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        /* 103.659% */

    }

    .ban-btn {
        padding: 5px;
    }

    .banner-input {
        width: 50%;
        padding: 3px !important;

    }

    .f-bg {
        width: 180px;
        padding-bottom: 30px;

    }


    .tech-img {
        background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Sectionmobile.png);
        height: 300px !important;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .tech-des h1 {
        color: #040E56;
        font-family: Urbanist;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        /* 116.667% */
    }

    .tech-des p {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;


    }

    .v {
        color: #497DFF;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
    }



    .footer {
        background-color: #040E56;
        width: 100%;
    }

    .footer-des h5 {
        white-space: nowrap;
        padding-bottom: 15px;
        font-family: Blinker;
    }

    .footer-des {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 90px;
    }

    .footer-des li {
        color: #EBEBEB;
        font-family: Roboto;
    }

    .testimonials {
        background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/testimonial.png);
        width: 100%;
        height: fit-content;
    }

    .testimonials h3 {
        color: #FFF;
        font-family: Urbanist;
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
        /* 100% */
    }

    .test-head {
        padding-left: 50px;
        padding-top: 20px;
        font-size: 10px;
    }

    .test-head h3 {
        font-size: 35px;
    }

    .t-client {
        width: 70px;
        height: 70px;
    }

    .client-des p {
        color: #040E56 !important;
        font-family: Montserrat;
        font-size: 7px;
        font-style: normal;
        font-weight: 200;
        line-height: 12px;
        /* 171.429% */
    }

    .client-btn {
        margin-left: 41px;
    }

    .client-des {
        background-color: white;
        border-radius: 5px;
        width: 450px;
    }

    .client-des {
        flex: 1;
        /* Adjust the width of each item */
        text-align: center;
        /* Center text and images */
    }


    .test-car {
        padding-top: 110px;
    }

    .client-details {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }

    .carousel {
        width: 400px;
        margin-left: 0px;
    }

    .test-arrow {

        font-size: 20px !important;
        display: flex;
        justify-content: center;
        font-size: 30px !important;
        margin-bottom: 30px !important;
        position: relative;
        bottom: -50px;
        right: 630px;


    }


    .client-box {
        background-color: #040E56;
    }


    .keyboard-img {
        background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/keyboard.png);
        height: fit-content;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding-bottom: 10px;
    }

    .intresting-des p {
        color: #497DFF;
        text-align: center;
        font-family: Urbanist;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        /* 100% */
    }

    .intresting-des h5 {
        color: #040E56;
        text-align: center;
        font-family: Urbanist;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        /* 100% */
        padding-bottom: 40px;

    }

    .touch-btn {
        height: 41px;
        padding: 7px 15px 8px 15px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 10px;
        background: #497DFF;
        border: none;
        color: white;
    }

    .intresting-des {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 80px;
    }

    .industries-des li {
        font-size: 13px !important;
    }

    .vision-all {
        display: block;
        justify-content: center;
        padding-top: 30px;
    }

    .service-all {
        display: block;
        margin-top: 20px;
    }

    .service2 {
        padding-top: 20px !important;

    }



    /* Main Menu */
    .menu {
        position: relative;
        display: inline-block;
    }

    .menu-item {
        cursor: pointer;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
    }

    /* Submenu */
    .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #f9f9f9;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        opacity: 0;
        /* visibility: hidden; */
        transition: opacity 0.3s ease, visibility 0.3s ease;
        z-index: 1;
    }

    .menu-item:hover .submenu {
        opacity: 1;
        visibility: visible;
    }

    /* Sub-submenu */
    .sub-submenu {
        position: absolute;
        top: auto;
        left: 0%;
        background-color: #f9f9f9;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        opacity: 0;
        /* visibility: hidden; */
        transition: opacity 0.3s ease, visibility 0.3s ease;
        z-index: 1;
    }

    .submenu-item:hover {
        background-color: #cecece7e;
    }

    .submenu-item:hover .sub-submenu {
        opacity: 1;
        visibility: visible;
    }

    .submenu-item,
    .sub-submenu-item {
        padding: 10px;
        cursor: pointer;

    }

    .sub-submenu-item {
        padding: 10px 20px;
    }

    .sub-submenu-item:hover {
        background-color: #cecece7e;
    }


    .insidesub-submenu {
        position: absolute;
        top: auto;
        left: 100%;
        background-color: #f9f9f9;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        z-index: 1;
    }

    .sub-submenu-item:hover .insidesub-submenu {
        opacity: 1;
        visibility: visible;
    }

    .sub-submenu-item,
    .insidesub-submenu {
        cursor: pointer;
    }

    .insidesub-submenu-item {
        padding: 10px 20px;
    }

    .insidesub-submenu-item:hover {
        background-color: #cecece7e;
    }




    .primary-menu-item {
        color: #000;
        padding: 15px;
        cursor: pointer;
        /* Main menu background */
        border-bottom: 1px solid #ddd;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    /* Submenu Styles */
    .dropdown-menu1 {
        display: none;
        background-color: #f5f5f5;
        /* Submenu background */
        padding: 0;
        left: 0;
        top: 100%;
        /* Position below the main menu item */
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
        opacity: 0;
        overflow: hidden;
    }

    /* Submenu Visible */
    .dropdown-menu1.visible {
        display: flex;
        opacity: 1;
    }

    /* Nested Submenu Styles */
    .dropdown-menu22 {
        display: none;
        background-color: #f5f5f5;
        /* Nested submenu background */
        padding: 0;
        top: 0;
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }

    /* Nested Submenu Visible */
    .dropdown-menu22.visible {
        display: block;
        max-height: 300px;
        /* Adjust based on content */
        opacity: 1;
    }

    /* Menu Item Styles */
    .menu-item,
    .menu-item2 {
        padding: 10px 20px;
        cursor: pointer;
    }


    /* Expand Indicator */
    .submenu-indicator {
        font-size: 12px;
        margin-left: 5px;
        display: inline-block;
        transition: transform 0.3s ease;
    }

    /* Rotate Indicator */
    .primary-menu-item.active .submenu-indicator {
        transform: rotate(45deg);
    }



}



















.head-img {
    height: 40px;
    width: 40px;

}


/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
    --header-height: 3.5rem;

    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
    --white-color: hsl(0, 0%, 0%);
    --black-color: hsl(0, 0%, 100%);

    /*========== Font and typography ==========*/
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font: "Montserrat Alternates", sans-serif;
    --h1-font-size: 1.5rem;
    --normal-font-size: .938rem;

    /*========== Font weight ==========*/
    --font-regular: 400;
    --font-medium: 500;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1150px) {
    :root {
        --normal-font-size: 1rem;
    }
}

/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--white-color);
    background-color: white;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/*=============== REUSABLE CSS CLASSES ===============*/


/*=============== HEADER & NAV ===============*/
.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--black-color);
    z-index: var(--z-fixed);
}

.nav {
    position: relative;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo {
    color: var(--white-color);
    font-weight: var(--font-medium);
}

.nav__close,
.nav__toggle {
    display: flex;
    color: var(--white-color);
    font-size: 1.5rem;
    cursor: pointer;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1150px) {
    .nav__menu {
        position: fixed;
        left: -100%;
        top: 0;
        background-color: var(--black-color);
        width: 100%;
        height: 100%;
        padding: 6rem 3.5rem 4.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: left .4s;
    }

    .nav__item span {
        color: white;
    }

    /* .nav__item {
    transform: translateX(-150px);
    visibility: hidden;
    transition: transform .4s ease-out, visibility .4s;
  } */

    .nav__item:nth-child(1) {
        transition-delay: .1s;
    }

    .nav__item:nth-child(2) {
        transition-delay: .2s;
    }

    .nav__item:nth-child(3) {
        transition-delay: .3s;
    }

    .nav__item:nth-child(4) {
        transition-delay: .4s;
    }

    .nav__item:nth-child(5) {
        transition-delay: .5s;
    }
}

.nav__list,
.nav__social {
    display: flex;
}

.nav__list {
    flex-direction: column;
    row-gap: 3rem;
}

.nav__link {
    position: relative;
    color: var(--white-color);
    font-size: var(--h1-font-size);
    font-weight: var(--font-medium);
    display: inline-flex;
    align-items: center;
    transition: opacity .4s;
}

.nav__link i {
    font-size: 2rem;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s, visibility .4s;
}

.nav__link span {
    color: #000;
    position: relative;
    transition: margin .4s;
}

.nav__link span::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 0;
    height: 2px;
    background-color: var(--white-color);
    transition: width .4s ease-out;
}

/* Animation link on hover */
.nav__link:hover span {
    margin-left: 2.5rem;
}

.nav__link:hover i {
    opacity: 1;
    visibility: visible;
}

.nav__link:hover span::after {
    width: 100%;
}

/* Sibling fade animation */
.nav__list:has(.nav__link:hover) .nav__link:not(:hover) {
    opacity: .4;
}

.nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.nav__social {
    column-gap: 1rem;
}

.nav__social-link {
    color: var(--white-color);
    font-size: 1.5rem;
    transition: transform .4s;
}

.nav__social-link:hover {
    transform: translateY(-.25rem);
}

/* Show menu */
.show-menu {
    left: 0;
    background-color: #040E56;
    color: white;
}


/* Animation link when displaying menu */
.show-menu .nav__item {
    visibility: visible;
    transform: translateX(0);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (min-width: 1150px) {


    .nav {
        height: calc(var(--header-height) + 2rem);
    }

    .nav__toggle,
    .nav__close {
        display: none;
    }

    .nav__link {
        font-size: var(--normal-font-size);
    }

    .nav__link i {
        font-size: 1.5rem;
    }

    .nav__list {
        flex-direction: row;
        column-gap: 3.5rem;
    }

    .nav__menu {
        display: flex;
        align-items: center;
        column-gap: 3.5rem;
    }
}

.dropdown-menu {
    position: relative;
    display: none;
    /* Hide all dropdown menus by default */

}

.dropdown-item:hover+.dropdown-menu,
.dropdown-menu:hover {
    display: block;
}

.dropdown-sub-menu {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    left: 100px;
    top: 0;
}

.dropdown-sub1-item {
    cursor: pointer;
    color: #000;
    text-decoration: none;
    white-space: nowrap;
    padding-left: 15px;
}

.dropdown-sub1-item:hover+.dropdown-sub-menu,
.dropdown-sub-menu:hover {
    display: block;
}

.dropdown-sub-item {
    text-decoration: none !important;
    color: black;
    white-space: nowrap !important;
    padding: 15px !important;
}

.dropdown-submenu {
    position: relative;
}


.nav__item .dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.nav__item:hover .dropdown-menu,
.dropdown-menu {
    display: block;
}



.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}




.dropdown-menu.sub1 {
    display: none;
}



/* Ensure dropdowns are correctly positioned */
.dropdown-menu {
    position: relative;
}


/* Main Menu */
.menu {
    position: relative;
    display: inline-block;
}

.menu-item {
    color: #121212 !important;
    cursor: pointer;
   /* display: flex; */
   justify-content: space-between;
   align-items: center;
   width: 100%;
    /* position: relative; */
    font-size: 16px;
    white-space: nowrap;
}

/* Submenu */
.submenu {
    position: absolute;
    top: 200%;
    left: 0;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1;
}

.menu-item:hover .submenu {
    opacity: 1;
    visibility: visible;
}

/* Sub-submenu */
.sub-submenu {
    position: absolute;
    top: auto;
    left: 100%;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1;
}

.submenu-item:hover {
    background-color: #cecece7e;
}

.submenu-item:hover .sub-submenu {
    opacity: 1;
    visibility: visible;
}

.submenu-item,
.sub-submenu-item {
    color: #000;
    font-size: 13px;
    padding: 8px;
    cursor: pointer;
    white-space: nowrap;

}

.sub-submenu-item {
    font-size: 13px;
    padding: 8px;
    white-space: nowrap;
}

.sub-submenu-item:hover {
    background-color: #cecece7e;
}


.insidesub-submenu {
    position: absolute;
    top: auto;
    left: 100%;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1;
}

.sub-submenu-item:hover .insidesub-submenu {
    opacity: 1;
    visibility: visible;
}

.sub-submenu-item,
.insidesub-submenu {
    cursor: pointer;
}

.insidesub-submenu-item {
    padding: 10px 20px;
}

.insidesub-submenu-item:hover {
    background-color: #cecece7e;
}

@media only screen and (max-width: 600px) {

    .choose h1 {
        font-size: 28px;
    }

    .testi-container p {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #040E56;

    }

}



/* styles.css from vilva code */
.footer {
    padding-top: 50px;
}

.footer-logo img {
    margin-bottom: 50px;
}

.footer.black-bg p {
    color: rgba(255, 255, 255, 0.5);
}

.footer.black-bg .social-icons li a {
    color: rgba(255, 255, 255, 0.5);
}

.footer.black-bg .social-icons li a:hover {
    color: rgba(255, 255, 255, 1) !important;
}

.footer p {
    font-size: 14px;
}

.footer-logo img {
    width: 65%;
}

.footer h6 {
    font-size: 14px;
    padding-left: 28px;
    padding-bottom: 15px;
}

.addresss-info li {
    font-size: 14px;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.5);
    list-style: none;
}

.addresss-info i {
    color: rgba(255, 255, 255, 0.5);
    padding-right: 5px;
    float: left;
    display: table-cell;
    width: 30px;
    line-height: 23px;
    font-size: 14px;
}

.addresss-info p {
    display: table;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0px;
}

.addresss-info li a {
    color: rgba(255, 255, 255, 0.5);
}

.footer a {
    font-size: 14px;
}

.addresss-info.text-black li,
.addresss-info.text-black p,
.addresss-info.text-black li a {
    color: #626262;
}

.addresss-info.text-black i {
    color: #007e60;
    font-size: 20px;
    width: 40px;
}

/*footer-Newsletter*/
.footer-Newsletter {
    display: inline-block;
    width: 100%;
}

.footer-useful-link.footer-hedding ul {
    margin-top: -6px;
}

.footer-Newsletter .form-control {
    border-left: 0px;
    height: 50px;
    border-radius: 3px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.3);
    border: none;
    background: rgba(255, 255, 255, 0.08);
    padding: 10px 25px;
    width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.footer-Newsletter .form-control:focus {
    border: 0;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.10);
}

.footer-Newsletter .button.button-border {
    border-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.footer-Newsletter .button.button-border:hover {
    color: #ffffff;
}

.footer-Newsletter .button.button-border:focus {
    border-color: transparent;
}

.footer-useful-link ul li {
    list-style: none;
    margin-bottom: 12px;
}

.footer-useful-link ul li:last-child {
    margin-bottom: 0;
}

.footer-useful-link ul li a {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    vertical-align: top;
    display: block;
    line-height: 26px;
}

.footer-useful-link ul li a:hover {
    color: #fff;
}

/*transparent*/
.footer-Newsletter.transparent .form-control {
    background: rgba(0, 0, 0, 0.04);
}

.footer-Newsletter.transparent .form-control::-moz-placeholder {
    color: #323232;
}

.footer-Newsletter.transparent .form-control::-ms-input-placeholder {
    color: #323232;
}

.footer-Newsletter.transparent .form-control::-webkit-input-placeholder {
    color: #323232;
}

/*footer-widget*/
.footer-widget {
    padding: 20px 0;
    border-top: 1px solid #262626;
}

.footer-widget p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    letter-spacing: 0.5px;
}

.footer-widget a {
    color: #fff;
}

.footer-widget a:hover {
    color: #007e60;
}

.footer-widget-social {
    margin-top: 5px;
}

.footer-widget-social li {
    display: inline-block;
}

.footer-widget-social a i {
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    background: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 37px;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.footer-widget-social a i:hover {
    background: #007e60;
}

.footer .footer-nav ul li {
    display: inline-block;
}

.footer .footer-nav ul li a {
    margin: 0 5px;
    color: #323232;
}

.footer .footer-nav ul li a:hover {
    color: #007e60;
}

.footer .social ul li {
    display: inline-block;
}

.footer .social ul li a i {
    font-size: 14px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    float: none;
    color: #323232;
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s
}

.footer .social ul li a i:hover {
    color: #007e60;
}

.footer .social ul {
    background: #f6f7f8;
    display: inline-block;
    border-radius: 3px;
    padding: 0px 10px;
}

.footer .usefull-link ul li {
    display: block;
    border-bottom: 0px;
    line-height: 24px;
    padding: 5px 0;
}

.footer .usefull-link ul li a {
    color: #323232;
}

.footer .usefull-link ul li a:hover {
    color: #007e60;
}

.footer .copyright {
    padding: 30px 0 20px;
}

.footer .copyright ul li a {
    color: #323232;
}

.footer .copyright ul li a:hover {
    color: #007e60;
}

img#logo-footer {
    height: 40px;
    width: auto;
}

.footer.light .footer-nav ul li a {
    color: #fff;
}

.footer.light .footer-nav ul li a:hover {
    color: #323232;
}

.footer.light h6 {
    color: #fff;
}

.footer.light .about-content p {
    color: #fff;
}

.footer.light .usefull-link ul li a {
    color: #fff;
}

.footer.light .usefull-link ul li a:hover {
    color: #323232;
}

.footer.light .copyright ul li {
    color: #fff;
}

.footer.light .copyright ul li a {
    color: #ffffff;
    font-size: 14px;
}

.footer.light .copyright ul li a:hover {
    color: #323232;
}

.footer.light.footer-topbar .copyright ul li {
    color: #ffffff
}

.footer.light .copyright p {
    color: #fff;
}

.footer.light .social ul li a i {
    color: #007e60;
}

.footer.light .social ul li a:hover i {
    color: #323232;
}

.footer .footer-text p {
    color: rgba(255, 255, 255, 0.5) !important;
    padding-top: 10px;
}

.footer .footer-social ul li a {
    color: rgba(255, 255, 255, 0.5) !important;
    padding-top: 10px;
}

.footer .footer-social ul li a:hover {
    color: #007e60 !important;
}

.footer.footer-topbar .copyright ul li {
    color: rgba(255, 255, 255, 0.5) !important;
}

.footer.footer-one-page .contact-add i {
    font-size: 20px
}

.footer.footer-one-page h5 {
    color: rgba(255, 255, 255, 0.5);
}

.footer.footer-one-page p {
    color: rgba(255, 255, 255, 0.5);
}

.footer-one-page.white-bg .footer-Newsletter .form-control:focus {
    background: rgba(0, 0, 0, 0.06);
}

/* footer transparent*/
.footer.transparent h6,
.footer.transparent p,
.footer.transparent ul li a,
.footer.transparent span {
    color: #ffffff;
}

/*widget-gallery*/
.footer .footer-gallery ul li {
    float: left;
    width: 58px;
    margin: 2px;
    list-style: none;
}

.footer .footer-tags li {
    display: inline-block;
}

.footer .footer-tags li a {
    background: transparent;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    padding: 8px 16px;
    font-size: 13px;
    margin-right: 2px;
    margin-bottom: 5px;
    display: block;
}

.footer .footer-tags li a:hover {
    background: #007e60;
}

/*footer-social-big*/
.footer-social-big ul li {
    display: inline-block;
    padding: 10px;
    width: 70px;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
}

.footer-social-big ul li.facebook {
    background: #5d82d1;
}

.footer-social-big ul li.pinterest {
    background: #e13138;
}

.footer-social-big ul li.linkedin {
    background: #238cc8;
}

.footer-social-big ul li i {
    color: #ffffff;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin: 0 auto;
}

.footer-social-big ul li span {
    display: block;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
    font-size: 11px;
}

/*footer-box*/
.footer-box {
    background: rgba(102, 102, 102, 0.2);
    padding: 30px;
    clear: both;
    margin-bottom: 40px;
    margin-top: 60px;
}

.footer-box .box-content {
    display: table-cell;
    vertical-align: top;
}

.footer-box .box-link {
    display: table-cell;
    padding-left: 20px;
    vertical-align: middle;
}

.footer-box .box-link a {
    display: block;
    padding: 8px 16px;
    width: 140px;
}
















/* ecomcss code */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* @font-face {
    font-family: 'Neue Haas Unica';
    src: 
    url('./neue-haas-unica/NeueHaasUnica-Bold.woff2') format('woff2'),
         url('../ecom-fonts/NeueHaasUnica-Medium.woff2') format('woff2'),
         url('../ecom-fonts/NeueHaasUnica-Regular.ttf') format('ttf');
    font-weight: normal; 
    font-style: normal; 
} */
/* .ecom_main_text{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
} */
.star_rating {
    /* padding: 30px 0px; */
    background-color: #FFF9E4;
    z-index: 0;
}

.star_rating p {
    z-index: 1000 !important;
    color: #000;
    font-family: Roboto;
    font-size: 17.944px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: fit-content;
}

.ecom_main_text h1 {
    margin-top: 70px;
    color: #040E56;
    font-family: Lato;
    font-size: 52px;
    font-style: normal;
    font-weight: 900;
    line-height: 80px;
    white-space: nowrap;
}

.ecom_main_text p {
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    /* font-weight: 800; */
}

.ecom_main_text span {
    background: linear-gradient(89deg, #040E56 18.39%, #00C89E 57.04%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Lato;
    font-size: 72px;
    font-style: normal;
    font-weight: 900;
    line-height: 80px;
    /* 111.111% */
}

.ecom_main_text a {
    margin-top: 20px;
    text-decoration: none;
    display: flex;
    padding: 13px 42px;
    justify-content: center;
    align-items: center;
    gap: 13.375px;
    border-radius: 12px;
    background: #040E56;
    width: fit-content;
    color: #FFF;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.gstar_img1 {
    position: relative;
    top: 44px;
    width: 25px;
}

.gstar_img2 {
    width: 140px;
    position: relative;
    top: 44px;
    box-shadow: 0px 0px 20px 0px rgba(138, 96, 0, 0.20);
}

.partners_swiper-container {
    overflow: hidden;
}

.partners_swiper-container .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;

}

.partners_swiper-container .swiper-slide img {
    align-items: center;
    display: flex;
    height: 106px;
    object-fit: contain;
    width: 70%;
}

.partners_swiper-container .swiper-slide {
    background: none !important;
}



.all_in_one {
    background-color: #EDFFFF;
}

.all_in_one_text {
    text-align: center;
    position: relative;
    bottom: 110px;
}

.positionchange {
    position: relative;
    bottom: 80px;
}

.all_in_one_text h2 {
    color: #000;
    font-family: Lato;
    font-size: 46px !important;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.all_in_one_text p {
    color: #000000b9;
    text-align: center;
    font-family: "Neue Haas Unica";
    font-size: 18px !important;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    /* 150% */
}

.allinone_sidebar {
    background-color: white;
    /* width: 70%; */
    padding: 0px 0px;
    align-items: center;
    border-radius: 20px;
    box-shadow: 1px 2px 10px #0000001a;
    /* width: fit-content; */
}

.new_heading {
    cursor: pointer;
    color: #000;
    padding: 20px 30px;
    font-family: "Neue Haas Unica";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.086);
}

.allinone_sidebar.active {
    border-right: 4px solid #00C89E;
}

.new_heading.active {
    color: #00C89E;
}

.allinone_content {
    /* background-color: white; */
    /* width: 520px; */
    padding: 0px 0px;
    align-items: center;
    border-radius: 20px;
    /* box-shadow: 1px 2px 10px #0000001a; */
    /* width: fit-content; */
}

.new_heading.active {
    border-right: 4px solid #00C89E;
}

.new_content {
    padding: 20px;
    flex-grow: 1;
    height: 550px;
    overflow-y: scroll;
}

/* new_content scrollbar styling */
.content::-webkit-scrollbar {
    display: none;
}


.new_content-item {
    display: block;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
}

.new_content-item.active {
    display: block;
    /* You can keep this if you need special styling for active new_content */
}

.new_content-item p:nth-child(2) {
    color: #000;
    font-family: Lato;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.new_content-item p:nth-child(3) {
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
}

.new_content-item a {
    text-decoration: none;
    color: #00C89E;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    /* 130% */
}

.e_com_store_text {
    text-align: center;
}

.e_com_store_text h3 {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 42px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.e_com_store_text p {
    color: #000000bb;
    text-align: center;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    width: 80%;
    margin: 0px auto;
    padding-bottom: 80px;

}

.view_btn {
    margin-top: 10px;
    text-decoration: none;
    color: #040E56;
    width: fit-content;
    border-radius: 6.982px;
    border: 1px solid #040E56;
    display: flex;
    padding: 10px 18px;
    align-items: center;
    gap: 7.758px;
    color: #040E56;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 35px 0px;
}

.our_clients_text {
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #F2F9FD;
}

.our_clients_images {
    background-color: #F2F9FD;

}

.our_clients_text h3 {
    flex: 1;
    margin: 0;
    color: #040E56;
    text-align: center;
    font-family: Lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 116.667% */
    white-space: nowrap;
}

.our_clients_text::before,
.our_clients_text::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #0000002a;
    /* Change the color as needed */
    margin: 0 10px;
    /* Space between the line and the text */
}

.our_clients_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.business_section h4 {
    text-align: center;
    padding-bottom: 20px;
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 37px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.business_section_text p {
    text-align: center;
    padding-top: 10px;
    color: #000;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.business_section_text span {
    text-align: center;
    color: #000;
    text-align: center;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
}

.price_section_container {
    background-color: #00866A;
}

.price_section_container h4 {
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.price_section_container p {
    color: #FFF;
    text-align: center;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
}

.price_container {
    position: relative;
    width: 450px;
    background: linear-gradient(205deg, #F3FFFC 5.43%, #E0F9FF 50.3%, #D8FFF7 102.98%);
    padding: 20px;
    text-align: center;
    border-radius: 16px;
}

.price_container h3 {
    color: #040E56;
    text-align: center;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 66.667% */
}

.price_container span {
    color: #040E56;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.price_container p {
    color: #000;
    text-align: center;
    font-family: "Neue Haas Unica";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 173.333% */
}

.price_group_con1 {
    background-color: #00866A;
}

.recommended {
    background: #FFA22E;
    color: #FFF !important;
    text-align: center;
    font-family: "Neue Haas Unica";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: fit-content;
    padding: 0px 10px;
    position: absolute !important;
    top: 40px;
    right: 0;
}

.price_container b {
    white-space: nowrap;
    color: #00C89E;
    font-family: Lato;
    font-size: 68px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.price_container b span {
    color: #00C89E;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.price_conditions {
    padding: 10px;
}

.price_conditions li {
    white-space: nowrap;
    list-style: none;
    text-align: start;
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 157.895% */
    padding-bottom: 10px;
}

.price_conditions li i {
    background-color: #040E56 !important;
    border-radius: 50%;
    padding: 5px;
    font-size: 10px;
    margin-right: 10px;
}

.price_conditions li b {
    list-style: none;
    text-align: start;
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 157.895% */
}

.price_container a {
    margin: 0 auto;
    text-decoration: none;
    border-radius: 16px;
    background: #00C89E;
    display: flex;
    width: 100%;
    padding: 20px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    text-align: center;
    font-family: "Neue Haas Unica";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.review_container {
    border-radius: 24px;
    background: #FFFADE;
}

.review_image {
    display: flex;
    flex-direction: column;
    /* Align items vertically */
    align-items: center;
    /* Center the middle content horizontally */
    height: 200px;
    /* Adjust based on your layout */
    justify-content: space-between;
    /* Distribute space between images and the content */
}

.reviw_customer {
    background-image: url("https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/review_img.webp");
    background-repeat: no-repeat;
    background-size: contain;
    height: 100px;
    /* Adjust based on your background image size */
    display: flex;
    justify-content: center;
    /* Centers the text horizontally */
    align-items: center;
    /* Centers the text vertically */
}

.top-aligned {
    align-self: flex-start;
}

.bottom-aligned {
    align-self: flex-end;
}



.faq_section {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.faq_section h2 {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px;
    /* 155% */
    padding-bottom: 30px;
}

.faq_questions {
    border: 1px solid #0d0d0d33;
    padding: 5px 10px;
    border-radius: 16px;
    margin-bottom: 10px;
}

.question_sec p {
    margin-top: 10px;
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}

.question_sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

}

.answer {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
}

.answer.open {
    height: auto;
    /* Set to 'auto' once opened */
    opacity: 1;
    transition: height 0.3s ease-in, opacity 0.3s ease-in;
    font-family: "Neue Haas Unica";
    font-size: 13px;
}

.question_sec i {
    transition: transform 0.3s ease;
}

.question_sec i.rotate {
    transform: rotate(180deg);
    /* Rotate the icon when open */
}

.freeedom_section {
    display: flex;
    align-items: center;
    justify-content: end;
    background-image: url("https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/background.webp");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    /* height: 300px; */
}

.freeedom_section h4 {
    color: #040E56;
    text-align: center;
    font-family: Lato;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.freeedom_section p {
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.freeedom_section a {
    text-decoration: none;
    display: flex;
    padding: 12px 22px;
    justify-content: center;
    align-items: center;
    gap: 13.375px;
    border-radius: 12px;
    background: #040E56;
    color: #FFF;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: fit-content;
}

.freeedom_section span {
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mySwiper_store {
    margin-top: 80px;
}

.mySwiper_store .swiper-scrollbar {
    position: absolute !important;
    top: 1px;
    /* Place the scrollbar at the top */
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    /* Height of the scrollbar */
    background: rgba(0, 0, 0, 0.1);
    /* Track color */
}

.swiper-horizontal>.swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
    top: 0 !important;

}

.swiper-scrollbar .swiper-scrollbar-drag {
    background: #00C89E !important;
    /* Color of the scrollbar */
    border-radius: 4px;
    height: 8px;
    /* Rounded corners */
}

/* Hide pagination */
.swiper-pagination {
    display: none;
    /* Remove pagination */
}

.swiper-container {
    width: 100%;
    height: 300px;
}

.review_text p {
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 160% */
}

.price_group_con {
    display: flex;
    justify-content: center;
    gap: 76px;
}

/* Tab View CSS  */

@media screen and (max-width: 1150px) {
    .ecom_main_text h1 {
        font-size: 40px;
        line-height: 60px;

    }

    .ecom_main_text span {
        font-size: 40px;
    }

    .ecom_main_text p {
        font-size: 15px;
        line-height: 30px;

    }
}



/* Mobile  View CSS  */

@media screen and (max-width: 900px) {
    .welcome {
        padding: 15px;
    }

    .service-dif {
        width: 100%;
    }

    .price_container {
        width: 100%;
    }

    .price_group_con {
        display: block;

        gap: 0px;
    }

    .gstar_img1 {
        top: 20px;
    }

    .gstar_img2 {
        top: 13px;

    }

    .ecom_main_text h1 {
        font-size: 27px;
        line-height: 8px;

    }

    .ecom_main_text span {
        font-size: 38px;
    }

    .ecom_main_text p {
        font-size: 13px;
        line-height: 20px;

    }

    .ecom_main_text a {
        padding: 10px 22px;
        font-size: 15px;
    }

    .all_in_one_text {
        bottom: 19px;
    }

    .all_in_one_text h2 {
        font-size: 26px !important;
    }

    .all_in_one_text p {
        font-size: 13px !important;
    }

    .e_com_store_text h3 {
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        padding-bottom: 30px;
    }

    .e_com_store_text p {
        font-size: 13px !important;
        width: 100%;
    }

    .view_btn {
        padding: 8px 9px;
        font-size: 12px;
    }

    .our_clients_text {
        display: block;
    }

    .our_clients_text h3 {
        font-size: 12px;
        white-space: wrap;
        width: 70%;
        margin: 0 auto;
    }

    .business_section_text p {
        font-size: 22px;
    }

    .business_section_text span {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .faq_section h2 {
        font-size: 24px;
    }

    .question_sec p {
        font-size: 14px;
    }

    .freeedom_section h4 {
        font-size: 28px;

    }

    .freeedom_section p {
        font-size: 14px;
        text-align: center;
    }

    .freeedom_section a {
        font-size: 14px;
        text-align: center;
        width: 100%;

    }

    .freeedom_section span {
        font-size: 14px;
        text-align: center;
        display: none;
    }

    .price_section_container h4 {
        font-size: 30px;
    }

    .price_section_container p {
        font-size: 15px;

    }

    .price_container b {
        font-size: 38px;

    }

    .price_section_container p {
        font-size: 16px;
    }

    .price_conditions li {
        font-size: 12px;
        padding-bottom: 0px;
    }

    .price_container a {
        margin-top: 20px;
        width: 100%;
        font-size: 14px;
        padding: 12px;
    }

    .review_text p {
        padding-top: 20px;
        color: #000;
        font-family: "Neue Haas Unica";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 160% */
    }

    .answer.open {
        font-size: 12px;
    }

    .content-item p:nth-child(2) {
        font-size: 18px;
    }

    .content-item p:nth-child(3) {
        font-size: 15px;

    }

    .content-item a {
        font-size: 12px;

    }

    .business_section h4 {
        font-size: 18px;

    }

    .recommended {
        font-size: 10px !important;
        z-index: 0;
        top: 50px;
    }

    .positionchange {
        position: relative;
        bottom: 0px;
    }
}



/* Templateone css */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Jost', sans-serif;
}


.ai-img {
    /* background-image: url('../internal/templatesimages/banner.avif'); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 650px;
    background-position: center;
    border-radius: 20px;
    padding: 30px;
}

.heading-about {
    /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.051)), url(../internal/images/pattern-bg.png); */
    height: 300px;
    width: 100%;
    margin-bottom: 50px;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 90px;
}


.ban-des {
    color: black;
    padding: 50px;
    /* width: 450px; */
    /* border-radius: 30px; */
    position: relative;
    /* top: 200px; */
    left: 90px !important;


}

.prod-head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
}

.ready-des {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
}

.ready {
    width: 100%;
    box-shadow: 0 5px 60px #3339582c;
    border: 1px solid #dfdced;
    border-radius: 30px;
    /* padding: 69px 120px; */
    position: relative;
}

.contact-us {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.layout-btn button {
    background-color: #040e56;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-us button {
    background-color: #040e56;
    color: #fff;
    font-size: 16px;
    padding: 10px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-right: 10px;
}

.contact-us button i {
    margin-left: 10px;
}

.contact-us button:hover {
    background-color: #4a12f0;
    /* Change this to a darker shade of the button color */
    transform: scale(1.05);
}

.contact-us button:focus {
    outline: none;
}

.ben-ai {
    border: 2px solid #040e56;
    padding: 30px;
    border-radius: 20px;
    margin: 15px;
    text-align: center;
}

.ben-ai img {
    width: 100%;
    /* box-shadow: 0 5px 60px #3339582c; */
    box-shadow: 0 5px 20px #3339582c;
    border: 1px solid #dfdced;
    border-radius: 50%;
    /* padding: 69px 120px; */
    position: relative;
    margin-bottom: 30px;
}

.benifits {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    padding-top: 30px;
}

.usecase-head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    text-align: center;
}

.useimg {
    /* background-image: url(./images/v617batch2-bb-02-technology.jpg); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 90%;
    /* height: 600px; */
    height: 100%;
    border-radius: 30px;
}

.latest-img-all img {
    width: 50%;
    height: auto;
    padding-left: 10px;
    border-radius: 50px;
    position: relative;
    top: 40px;
}

.img-des {
    padding: 30px;
    position: relative;
    top: 40px;
}

.latest-content {
    display: flex;
    align-items: flex-start;
}

.latest-content .text-side {
    padding-left: 15px;
}

.latest-img-all {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: row;
    padding-top: 40px;
    background: white;
    box-shadow: 0 0 30px #3339582e;
    border: 1px solid #dfdced;
    border-radius: 10px;
    transition: all .3s ease-out;
    width: 100%;
    height: 100%;
}

.read-more i {
    font-size: 25px;
}

.read-more button {
    background-color: #040e56;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border-radius: 30px;
    display: none !important;
}

.b-btn {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.b-btn button {
    background-color: #040e56;
    color: #fff;
    font-size: 16px;
    padding: 10px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-right: 10px;


}

.blog {
    background: white;
    box-shadow: 0 0 30px #3339582e;
    border: 1px solid #dfdced;
    border-radius: 10px;
    transition: all .3s ease-out;
    width: 100%;
    height: 100%;
    padding: 30px;
    margin: 15px;
}


@media (max-width: 768px) {
    .ban-des h1 {
        font-size: 1em;
    }

    .ban-des p {
        font-size: 0.9em;
    }

    .ban-des a {
        padding: 8px;
        font-size: 0.9em;
    }

    .ban-des {
        color: black;
        padding: 0px;
        width: 80%;
        border-radius: 30px;
        position: relative;
        top: -8px;
        left: 40px !important;

    }

    .latest-img-all {
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;
        padding-top: 40px;
        background: white;
        box-shadow: 0 0 30px #3339582e;
        border: 1px solid #dfdced;
        border-radius: 10px;
        transition: all .3s ease-out;
        width: 100%;
        height: 100%;
    }

    .latest-img-all img {
        width: 50%;
        height: auto;
        padding-left: 10px;
        border-radius: 50px;
        position: relative;
        /* top: 180px; */
    }

    .img-des {
        padding: 30px;
        position: relative;
        /* top: 40px; */
    }


    .useimg {
        /* background-image: url("./images/v617batch2-bb-02-technology.jpg"); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 90%;
        /* height: 600px; */
        height: 100%;
        border-radius: 30px;
    }

    .ben-ai img {
        width: 50%;
    }




}

/* template 2 css */

.con-layout2 {
    padding-top: 80px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.heading-about {

    /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.051)), url(../internal/images/pattern-bg.png);; */
    height: 300px;
    width: 100%;
    margin-bottom: 50px;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 90px;
}

.top-bg {
    background-color: rgba(6, 158, 201, .2);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    min-height: 172px;
}

.top-bg-c {
    background-color: rgba(6, 158, 201, .2);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    min-height: 172px;
}


.layout-col-2 {
    background: white;
    box-shadow: 0 0 30px #3339582e;
    border: 1px solid #dfdced;
    border-radius: 10px;
    transition: all .3s ease-out;
    width: 100%;
    height: 100%;
    padding: 20px;
    /*margin: 20px;*/
}

.imgforcol {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 120px;
    left: 130px;
}

.imgforcol-test {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 120px;
    left: 33%;

}

.layout-col-2 h3 {
    padding-top: 50px;

}

.project {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /*width: 70%;*/
    padding-bottom: 50px;

}

.project img {
    border-radius: 60px;

}


.counter-wrapper {
    /* background: url(./images/success.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.5rem;
    padding: 10rem 9%;
    margin-top: 5rem;
    position: relative;
    height: 60%;
}

.counter-wrapper::before {
    position: absolute;
    content: '';
    content: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.counter {
    text-align: center;
    color: #ddd;
    z-index: 2;
    position: relative;
}

.counter::before {
    position: absolute;
    content: '';
    bottom: -2rem;
    left: 50%;
    width: 20%;
    height: .2rem;
    background: #4DB7FE;
    border-radius: .5rem;
    -webkit-border-radius: .5rem;
    -moz-border-radius: .5rem;
    -ms-border-radius: .5rem;
    -o-border-radius: .5rem;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.counter .count {
    font-size: 5rem;
    margin-bottom: 1rem;
}

.counter p {
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.project-des-con {
    margin-top: 130px;
    background: white;
    box-shadow: 0 0 30px #3339582e;
    border: 1px solid #dfdced;
    border-radius: 10px;
    transition: all .3s ease-out;
    width: 100%;
    padding: 20px;



    /* margin: 20px; */
}


.layout-btn button {
    background-color: #040e56;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.responsive-img {
    width: 100%;
    height: auto;
    max-width: 100%;
}




/* @media only screen and (min-width: 320px) and (max-width: 590px) {
    .imgforcol{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 120px;
        left: 108px !important;
    }
     .imgforcol-test{
         background-repeat: no-repeat;
         background-size: cover;
         background-position: center;
         position: absolute;
         top: 120px;
         left: 108px !important;
     
     }

} */


/* Small mobile devices (e.g., portrait phones, less than 600px) */
@media only screen and (max-width: 599px) {

    .project img {
        width: 100%;
    }

    .project-des-con {
        margin-top: 1px;
    }

    .project-des-con h2 {
        font-size: 22px;
    }

    .project-des-con p {
        font-size: 16px;
        padding: 5px;
    }

    .con-layout2 h1 {

        font-size: 24px;
    }

    .con-layout2 p {
        font-size: 16px;
        margin-top: 10px;
    }

    .layout-col-2 h3 {
        font-size: 19px;
    }

    .imgforcol {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 120px;
        left: 145px;
    }

    .imgforcol-test {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 120px;
        left: 33%;

    }
}

/* Medium mobile devices (e.g., landscape phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
    body {
        background-color: white !important;
    }
}

/* Large mobile devices (e.g., large phones, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    body {
        background-color: white !important;
    }
}

/* Tablets (e.g., iPads, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    body {
        background-color: white !important;
    }
}



/* template 3 css */


@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Jost;
}

.heading-about {
    /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.051)), url(../internal/images/pattern-bg.png); */
    height: 300px;
    width: 100%;
    margin-bottom: 50px;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 90px;
}

/* @font-face {
	font-family: Poppins;
	src: url(./fonts/Poppins-Medium.ttf);
} */
.main {
    width: 100%;
    height: auto;
    display: grid;
    place-items: center;
    /* background-color: rgb(245, 245, 245); */
    background-color: #fff;
    padding: 50px 0;
}

.main .head-t3 {
    font-size: 29px;
    color: rgba(91, 14, 216, 0.767);
    position: relative;
    margin-bottom: 100px;
    font-weight: 500;
}

.main .head-t3::after {
    content: " ";
    position: absolute;
    width: 50%;
    height: 3px;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    background-image: linear-gradient(to right, rgba(91, 14, 216, 0.767), rgba(238, 12, 200, 0.747));
}

/* Container Css Start  */
/* 
.container {
	width: 70%;
	height: auto;
	margin: auto 0;
	position: relative;
}
.container .t3-ul {
	list-style: none;
}
.container .t3-ul::after {
	content: " ";
	position: absolute;
	width: 2px;
	height: 100%;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	background-image: linear-gradient(to bottom, rgba(91, 14, 216, 0.767), rgba(238, 12, 200, 0.747));
}
.container .t3-ul .t3-li {
	width: 50%;
	height: auto;
	padding: 15px 20px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.218);
	position: relative;
	margin-bottom: 30px;
	z-index: 99;
}
.container .t3-ul .t3-li:nth-child(4) {
	margin-bottom: 0;
}
.container .t3-ul .t3-li .circle {
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	top: 0;
	display: grid;
	place-items: center;
} */
.circle::after {
    content: ' ';
    width: 12px;
    height: 12px;
    background-color: #7f00ff;
    border-radius: 50%;
}

.t3-ul .t3-li:nth-child(odd) .circle {
    transform: translate(50%, -50%);
    right: -30px;
}

.t3-ul .t3-li:nth-child(even) .circle {
    transform: translate(-50%, -50%);
    left: -30px;
}

.t3-ul .t3-li .date {
    position: absolute;
    width: 130px;
    height: 33px;
    /* background-image: linear-gradient(to right,#7f00ff,#e100ff); */
    background-image: linear-gradient(to right, #040e56, #1a237e, #304ffe);

    border-radius: 15px;
    top: -45px;
    display: grid;
    place-items: center;
    color: #fff;
    font-size: 13px;
    box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.318);
}

.container .t3-ul .t3-li:nth-child(odd) {
    float: left;
    clear: right;
    text-align: right;
    transform: translateX(-30px);
}

.t3-ul .t3-li:nth-child(odd) .date {
    right: 20px;
}

.container .t3-ul .t3-li:nth-child(even) {
    float: right;
    clear: left;
    transform: translateX(30px);
}

.t3-ul .t3-li .heading-t3 {
    /* font-size: 17px; */
    color: rgb(91, 14, 216);
}

.t3-ul .t3-li p {
    /* font-size: 13px; */
    color: #666;
    line-height: 18px;
    margin: 6px 0 4px 0;
}

.t3-ul .t3-li a {
    /* font-size: 13px; */
    text-decoration: none;
    color: rgb(18, 54, 214);
    transition: all 0.3s ease;
}

/* 
@media only screen and (min-width:798px) and (max-width: 1100px) {
    .container {
        width: 80%;
    }
}

@media only screen and (max-width: 798px) {
    .container {
        width: 70%;
        transform: translateX(20px);
    }

    .container .t3-ul::after {
        left: -40px;
    }

    .container .t3-ul .t3-li {
        width: 100%;
        float: none;
        clear: none;
        margin-bottom: 80px;
    }

    .container .t3-ul .t3-li .circle {
        left: -40px;
        transform: translate(-50%, -50%);
    }

    .container .t3-ul .t3-li .date {
        left: 20px;
    }

    .container .t3-ul .t3-li:nth-child(odd) {
        transform: translateX(0px);
        text-align: left;
    }

    .container .t3-ul .t3-li:nth-child(even) {
        transform: translateX(0px);
    }
}

@media only screen and (max-width: 550px) {
    .container {
        width: 80%;
    }

    .container .t3-ul::after {
        left: -20px;
    }

    .container .t3-ul .t3-li .circle {
        left: -20px;
    }
} */


/* template 4 css */

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Jost:ital,wght@0,100..900;1,100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Jost:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Jost:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

body {
    margin: 0;
    padding: 0;
}

.about-us {
    /* background-image: url(../internal/templatesfourimages/About.webp); */
    background-image: url('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/aboutbanner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* height: 400px; */
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 60PX;
    margin-top: 77px;
}

.about-us h1 {
    text-align: center;
    color: #56FFBD;
    font-size: 60px;
    font-family: Urbanist;
    font-weight: 700;


}

.about-us p {
    /* width: 50%; */
    text-align: center;
    line-height: 30px;
    color: #EBEBEB;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 400;


}

.best-tech {
    padding-top: 80px;
    padding-bottom: 20px;
}

.best-tech-img {
    background-image: url('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/aboutvr.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 90%;
    height: 500px;

}

.best-tech-des {
    padding-top: 100px;
}

.best-tech-des h5 {
    color: #497DFF;
    font-size: 22px;
    font-family: Montserrat;
    font-weight: 600;


}

.best-tech-des h2 {
    color: #040E56;
    font-size: 48px;
    padding-bottom: 15px;
    font-family: Urbanist;
    font-weight: 700;



}

.best-tech-des p {
    color: #9B9B9B;
    font-size: 16px;
    padding-bottom: 25px;
    line-height: 24px;
    font-family: Montserrat;
    font-weight: 400;


}

.best-tech-btn {
    background-color: #497DFF;
    color: white;
    border: none;
    padding: 7px 12.31px 8.5px 13px;
    border-radius: 7px;
}

.project-completed h3 {
    color: #040E56;
    font-size: 28px;
    font-family: Urbanist;
    font-weight: 700;


}

.project-completed p {
    color: #9B9B9B;
    font-size: 16px;
    line-height: 24px;
    font-family: Montserrat;
    font-weight: 400;


}

.projects {
    padding-top: 50px;
    padding-bottom: 50px;
}

.history-all {
    background-image: url('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/History.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.history-des h5 {
    color: #56FFBD;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    /* 104.167% */
    padding-bottom: 10px;
}

.history-des h3 {
    color: #FFF;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    /* 116.667% */
    padding-bottom: 10px;

}

.history-des p {
    color: #EBEBEB;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 160% */
    padding-bottom: 10px;

}

.history-btn {
    background-color: #497DFF;
    color: white;
    border: none;
    padding: 7px 18.31px 8.5px 19px;
    border-radius: 5px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
}

.history-img {
    /* background-image: url('./img/vr.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* height: 400px; */
    padding-bottom: 60PX;
    padding-top: 90px !important;
}

.history-des {
    padding-top: 150px;
}

.our-client {
    padding-top: 50px;
}

.our-client h3 {
    color: #040E56;
    font-family: Urbanist;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    /* 112% */
    text-align: center;
}

.client-img img {
    padding-top: 60px;
    width: 95%;
    padding-bottom: 60px;

}

.our-client p {
    color: #171717;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.our-values {
    background-color: #040E56;
}

.our-values-des h3 {
    color: #FFF;
    font-family: Urbanist;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    /* 55px */
    text-align: center;
}

.our-values-des h2 {
    text-align: center;
    color: #FFF;
    font-family: Mulish;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 33px */
    padding-top: 20px;
    padding-bottom: 20px;
}

.our-values-des p {
    color: #FFF;
    text-align: center;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.5%;
    /* 30.1px */
}

.innovation-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* get in touch starts here */

.get-in-touch h2 {
    color: #040E56;
    font-family: Urbanist;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    /* 112% */
    text-align: center;
    padding-bottom: 30px;
}

.get-in-touch label {
    color: #242331;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 30px */
    text-transform: capitalize;
}

.get-in-touch button {
    background-color: #040E56;
    color: white !important;
    border-radius: 50px !important;
    border: none !important;
    padding: 15px 18px 16px 18px;
}

.get-in-touch input,
textarea {
    border-radius: 20px;
    border: 1px solid #DCDCDC;
    background: #FFF;
    height: 60px;
    width: 100%;
    padding: 5px 20px;
}

.get-in-touch input:focus,
.get-in-touch textarea:focus {
    border-color: #007bff;
    /* Change this to any color you prefer */
    outline: none;
    /* Removes the default outline */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Optional: Adds a shadow for a more prominent effect */
}

.get-in-touch textarea {
    width: 100%;
    height: 200px;
    margin-bottom: 30px;
}

.get-in-touch form {

    padding: 15px;

}

.news-img {
    position: absolute;
    top: -162px;
}

/* form{
    box-shadow: 0 0 30px #3339582e;
    border: 1px solid #dfdced;
    border-radius: 15px;
    padding: 25px;
} */





/* get in touch ends here */



/* products css starts here */

.banner-img {
    /* background-image: url('../internal/templatesfourimages/Banner.webp'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content !important;
    height: fit-content;
    /*margin-left: 40px;*/
    /*margin-right: 40px;*/
    /* border-radius: 20px; */
    padding: 80px 0px 40px 0px;
}

.prod-des {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    flex-direction: column;
}

.prod-des h2 {
    color: #FFF;
    text-align: center;
    font-family: Urbanist;
    /* font-size: 60px; */
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    /* 66px */
}

/* .prod-des p{
    padding-top: 35px;
    width: 55%;
    color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
text-transform: capitalize;
} */
.prod-main-cont {
    overflow: hidden;
}

.prod-head-img {
    background-image: url('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/product.webp');
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover;
    width: 100%;
    height: 400px;
    border-radius: 20px;
}

.prod-head-content h2 {
    color: #2F2F2F;
    font-family: Urbanist;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    /* 44px */
    padding-bottom: 15px;
}

.prod-head-content p {
    color: #808080;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.5%;
    /* 30.1px */
}

.prod-head-content {
    /* padding: 90px; */
    padding: 90px 90px 0 90px;

}

.client-testimonial-des h2 {
    color: #2F2F2F;
    font-family: Urbanist;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    /* 44px */
    text-align: center;
    padding-bottom: 25px;
}

.test-img {
    /* background-image: url('../internal/templatesfourimages/Quotes.webp'); */
    background-image: url('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/Quotes.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100% !important;
    border-radius: 25px;
}

.test-img-des {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 50px; */
    padding-top: 140px;
}

.test-img-des p {
    color: #FFF;
    text-align: center;
    font-family: Mulish;
    font-size: 22px;
    font-style: italic;
    font-weight: 800;
    line-height: 150.5%;
    /* 61.705px */
    padding: 25px;
}

.news-letter-img {
    /* background-image: url('../internal/templatesfourimages/news.webp'); */
    background-image: url('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/news.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    /* height: 500px; */
    border-radius: 20px;
    padding: 15px;

}

.news-letter-img h3 {
    color: #FFF;
    font-family: Urbanist;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    /* 55px */
}

.news-letter-img p {
    color: #FFF;
    /* text-align: center; */
    font-family: Mulish;
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    line-height: 150.5%;
    /* 30.1px */
}

.news-letter-img button {
    background-color: black;
    color: white;
    border: none;
    border-radius: 45px;
    padding: 10px 30px;
}

.news-letter-img input {
    border: none;
    width: 100%;
    outline: none !important;
    ;
}

.radio-btn {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    column-gap: 15px;
}

.radio-btn i {
    color: #fff;
}

.radio-btn p {
    color: #FFF;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.5%;
    /* 30.1px */
}




/* products css ends here */



/* service starts below */


.service-head-img {
    /* background-image: url('../internal/templatesfourimages/UI.webp'); */
    background-image: url('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/aiml.png');
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover;
    width: 100%;
    height: 400px;
    border-radius: 20px;
}

.service-head-content h2 {
    color: #2F2F2F;
    font-family: Urbanist;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    /* 44px */
    padding-bottom: 15px;
}

.service-head-content p {
    color: #808080;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.5%;
    /* 30.1px */
}

.service-head-content {
    /* padding: 90px; */
    padding: 90px 90px 0 90px;

}

.technology-we-use h3 {
    color: #2F2F2F;
    text-align: center;
    font-family: Urbanist;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    /* 44px */
    padding-top: 15px;
    padding-bottom: 35px;
}

.technology-we-use p {
    color: #808080;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.5%;
    /* 30.1px */
    padding-bottom: 35px;

}

.technology-des {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.technology-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;
}

.recent-project h2 {
    color: #000;
    text-align: center;
    font-family: Urbanist;
    font-size: 37px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    /* 44px */
    padding-bottom: 50px;

}

.recent-img-des {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 15px;
}

.recent-img-des p {
    color: #2F2F2F;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 207.5%;
    /* 41.5px */
}

.recent-img-des h4 {
    color: #2F2F2F;
    font-family: Urbanist;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 36px */
}

.news-input {
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 100%;
    border-radius: 50px;
    border: 1px solid #DCDCDC;
    height: 53px;
    /* justify-content: center; */
    padding: 0px 10px 0 20px;
    justify-content: space-between;
}


/* media query starts here */

@media only screen and (max-width: 768px) {

    .service-head-content,
    .prod-head-content {
        /* padding: 90px; */
        padding: 30px 30px 0 30px;

    }

    .prod-des p {
        padding-top: 15px;
        width: 100%;
        /* color: #FFF; */
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        text-transform: capitalize;

    }

    .test-img-des p {
        color: #FFF;
        text-align: center;
        font-family: Mulish;
        font-size: 12px;
        font-style: italic;
        font-weight: 800;
        line-height: 150.5%;
        /* 61.705px */
        /* padding: 25px; */
    }

    .banner-img {

        margin-left: 0px;
        margin-right: 0px;
        /* border-radius: 20px;
        padding: 40 0 40 0px; */
    }

    .news-letter-img h3 {
        color: #FFF;
        font-family: Urbanist;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%;
        /* 55px */
    }

    .news-letter-img p {
        color: #FFF;
        /* text-align: center; */
        font-family: Mulish;
        font-size: 14px;
        font-style: italic;
        font-weight: 600;
        line-height: 150.5%;
        /* 30.1px */
    }


    .radio-btn p {
        color: #FFF;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150.5%;
        /* 30.1px */
    }

}

@media (max-width: 768px) {
    .choose {
        padding-top: 60px !important;
    }
    .prod-head-content h2 {
        font-size: 32px !important;
        padding-top: 25px;
    }
}


pay_method_logos{
    width: 100px;
    height: fit-content;
}
.pay_method_logos a img{
    object-fit: contain;
    height: 80px;
    /* width: 50px; */
}
.swiper-fade-effect {
    position: relative;
    overflow: hidden; /* Ensures the gradient effect stays inside the container */
  }
 
  .swiper-fade-effect::before,
  .swiper-fade-effect::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200px; /* Adjust the width of the fade as needed */
    z-index: 10;
    pointer-events: none; /* Allows interaction with the swiper beneath */
  }
 
  .swiper-fade-effect::before {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
 
  .swiper-fade-effect::after {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }


  @media screen and (max-width: 768px) {
    .swiper-fade-effect::before,
    .swiper-fade-effect::after {
        width: 40px;
    }
    .menu-item {
        color: #000 !important;
        cursor: pointer;
       display: flex;
       justify-content: space-between;
       align-items: center;
       width: 100%;
        position: relative;
        font-size: 11px;
        white-space: nowrap;
    }
  }
  .social-icons-list {
    display: flex !important;
    list-style: none !important;
    padding: 0;
    margin: 0;
    gap: 10px; /* Adds space between icons */
  }
  
  .social-icons-list li a {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 24px; /* Adjust icon size as needed */
    transition: color 0.3s;
  }
  
  .social-icons-list li a:hover {
    color: #007bff; /* Change color on hover */
  }
  