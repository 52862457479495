@font-face {
    font-family: 'Neue Haas Unica';
    src: url('../font/font/NeueHaasUnica-Regular.ttf') format('opentype');
         /* url('path/to/your/font.woff') format('woff'); */
    font-weight: normal;
    font-style: normal;
  }
  /* Target the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 10px; /* Height for horizontal scrollbar */
}

/* Track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Thumb (scrollbar handle) */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Hover effect on the scrollbar handle */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

  

/* SIDEBAR */

.dashboard-img{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 20px;
}
.dashboard-img img{
    width: 160px;
height: 32px;
}
.dash_details svg,.dash_details_ecom svg {
    margin-right: 12px; 
    color: #02138E;
  }
  
.dashboard_head p{
    color: #888;
font-family: "Neue Haas Unica";
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dash_details h3{
    padding: 0;
    margin: 0;
    color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dash_details_ecom h3{
    padding: 0;
    margin: 0;
    color: #fff;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dash_details{
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
padding: 8px 16px;
align-items: center;
align-self: stretch;
border-radius: 8px;
background: var(--Primary-Base-White, #FFF);
cursor: pointer;
margin-bottom: 4px;
}
.main_status{
  text-decoration: none !important;
  padding: 8px 16px;
    background: var(--Primary-Base-White, #FFF);
    cursor: pointer;
    margin-bottom: 4px;
    color: black;
}
.main_status a{
display: flex;
gap: 12px;
color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;  
}
.active_status{
  border-radius: 8px;
background: #FFF;
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.main_active_status{
  border-radius: 8px;
  border: 1px solid #B2BAF5;
  background: #D6DBFC;
  text-decoration: none !important;
  padding: 8px 16px;
    cursor: pointer;
    margin-bottom: 4px;
    color: black;
}
.main_active_status a{
  color: #02138E;
  display: flex;
gap: 12px;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;  
}
.submenu_active_status{
  border-radius: 8px;
  border: 1px solid #B2BAF5;
  background: #D6DBFC;
  display: flex;
width: 100%;
padding:10px 0px !important;
height: fit-content;
align-items: center;
gap: 12px;
flex-shrink: 0;
color: #02138E !important;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dash_details:hover {
    /* border: 1px solid #B2BAF5; */
    background: #D6DBFC;
    box-shadow: 0px 0px 5px 0px rgba(178, 186, 245, 0.25);
}
.dash_details_ecom{
    display: flex;
    align-items: center;
    justify-content: space-between;
height: 40px;
padding: 8px 16px;
align-items: center;
align-self: stretch;
border-radius: 8px;
background: var(--Primary-Base-White, #FFF);
cursor: pointer;
border-radius: 8px;
background: linear-gradient(95deg, #3187FF 23.63%, #00FBCD 100.14%);
box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.20) inset;
}
  
.profile-dash-all{
    display: flex;
width: 100% !important;
height: 56px;
padding: 8px 16px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #D6DBFC;
background: var(--Primary-Base-White, #FFF);
box-shadow: 0px 0px 4px 0px rgba(192, 178, 255, 0.20);
}
.profile-dash-all img{
    width: 40px;
height: 40px;
border-radius: 40px;
border: 2px solid #D6DBFC;
}
.profile-dash{
    display: flex;
    align-items: center;
}
.profile-dash-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}
.profile_mail{
    margin-left: 12px;
}
.profile_mail h6{
    margin: 0;
    padding: 0;
    color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-bottom: 4px;
}
.profile_mail p{
    margin: 0;
    padding: 0;
    color: #888;
font-family: "Neue Haas Unica";
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.profile_logout{
    cursor: pointer;
    display: flex;
width: 100% !important;
height: 40px;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 12px;
flex-shrink: 0;
border-radius: 8px;
border: 0.6px solid #F5B2B2;
background: #FFEAEA;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
margin-top: 8px;
}
.profile_logout h5{
margin: 0;
    color: #EF1717;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
margin-left: 12px;
padding: 0;
align-items: center;
}
.profile-info {
    display: flex;
    align-items: center;
  }
  .profile_icon {
    display: flex;
    align-items: center;
  }
  .dashboard_head{
    padding-left: 20px;
  }
  .dash_details_ecom{
    margin-bottom: 150px;
  }

  /* SIDEBAR END */

  /* SEARCHBAR STARTS */
  .saerch_bar_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
  }
  .exit_portal{
    cursor: pointer;
    display: flex;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
border: 0.8px solid #CCD1FC;
background: #D6DBFC;
  }
  .exit_portal h3{
    color: #02138E;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 107.692%; /* 16.154px */
margin: 0;
  }
  .search_dash{
    display: flex;
width: 480px;
height: 44px;
padding: 10px 24px;
align-items: center;
gap: 24px;
border-radius: 21px;
background: #F8F8F8;
  }
  .search_dash input{
    border: none;
    background-color:#F8F8F8 ;
    outline: none;
    width: 400px;

  }
  .search_profile_icons{
    cursor: pointer;
  }
  .search_dash input:focus{
    border: none !important;
    outline: none;

  }
  .search_profile_icons img{
    width: 44px;
height: 44px;
  }
  .search_profiles_all{
    display: flex;
    align-items: center;
    gap: 24px;
    margin-right: 24px;
  }

    /* SEARCHBAR END */

    /* INVOICE CSS STARTS */

.route_navigation p{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}

.route_navigation span{
    color: #999;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}
.route_filters p{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}

.route_filters{
    display: flex;
    gap: 8px;
    align-items: center;
}
.route_filters_all{
    display: flex;
    align-items: center;
    gap: 40px;
}
.route_navigation_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;
}
.payment_status{
    color: #137D07 !important;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: inline-flex;
padding: 4px 8px !important;
justify-content: center;
align-items: center;
gap: 10px;
background: #DCF8D8;
margin: 15px;
}
.table-container {
  overflow-x: scroll;
}

.table-container::-webkit-scrollbar {
  width: 8px; 
  height: 8px; 
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb {
  background: linear-gradient(95deg, #3187FF 23.63%, #00FBCD 100.14%); 
  border-radius: 10px; 
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
  border-radius: 10px;
}


.data_table  tr{
    color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.data-table {
    width: 100%;
    border-collapse: separate; /* Ensures spacing between rows */
    border-spacing: 0 10px; /* Adds spacing between rows */
  }
  
  /* .data-table th, */
  .data-table td {
    /* padding: 15px; Adds padding inside the cells */
    padding: 15px 15px 15px 0px ;
    /* border: 1px solid #ddd; Adds borders around cells */
    text-align: left;
    color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
  }
  
  .data-table th {
    text-align: center;
    background-color: #f2f2f2;
width: 100px;
padding: 10px 0px;
align-items: center;
gap: 10px;
color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  
  .data-table tr:hover {
    background-color: #f9f9f9; /* Optional: Highlight row on hover */
  }
  .data-table thead{
    height: 45px;
  }
  .view_btn_dashboard button{
    display: inline-flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 0.8px solid #CACACA;
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: navajowhite;
  }
.table_buttons{
    display: flex;
    gap: 8px;
}
.table_buttons button{
    display: inline-flex;
padding: 6px 12px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
border: 0.8px solid #CACACA;
color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.table_svg{
    display: flex;
    align-items: center;
    justify-content: center;
}
.payment_failed{
    color: #ED2525 !important;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: inline-flex;
padding: 4px 8px !important;
justify-content: center;
align-items: center;
gap: 10px;
background: #FBE9E9;
margin: 15px;
}
.customer_all{
    overflow: hidden;
}
.loading_loder{
  position: absolute;
  left: 50%;
}
    /* INVOICE CSS END */

/* WALLET */
.path_dashboard p{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}
.path_dashboard span{
    color: #999;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}
.graph_img_1{
    background-image: url('../images/balance.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 240px;
    width: 352px;


}
.graph_img_2{
    background-image: url('../images/spending.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 240px;
    width: 352px;


}
.graph_img_3{
    background-image: url('../images/added.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 240px;
    width: 352px;


}
.graph_img{
    display: flex;
    align-items: center;
    gap: 18px;
}
.balance{
    display: flex;
    align-items: center;
    gap: 8px;
}
.balance h2{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}
.this_month p{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}
.this_month{
    display: flex;
    align-items: center;
    gap: 8px;
}
.this_month_all{
    display: inline-flex;
    padding: 2px 4px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
background: #F2F2F2;
}
.balance_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.price p{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: normal;
padding-top: 26.5px;
padding-bottom: 58px;
}
.graph1_des{
    padding: 20px;
}
/* .graph_percent_all{
   
} */
.graph_percent_all span{
    color: #0DC410;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 0;
}
.g_percent{
    display: flex;
    align-items: center;
    gap: 4px;
padding: 2px 8px;
align-items: center;
gap: 4px;
border-radius: 6px;
background: #DCF8D8;
    width: 70px;

}
.drop_down_dash svg{
margin-left: 14px;
}
.drop_down_dash{
    padding-top: 20px;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: start;
   background: #F8F8F8;
}
.drop_down_dash a{
  color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
gap: 12px;
margin-bottom: 25px;
}

/* all products list css */
.dash_prod_all{
    border-radius: 12px;
border: 0.8px solid #EFEFEF;
background: #FFF;
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
padding: 20px;

}
.prod_img_dash{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.plan_status_all{
    display: flex;
align-items: center;
gap: 4px;
}
.plan_status_all p{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 0;
margin: 0;
}
.dash_prod_all h1{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
padding-top: 16px;
padding-bottom: 6px;
}
.dash_prod_all p{
    color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
padding-bottom: 32px;
}
.dash_prod_all a{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}
.plan_status_all button{
    color: #02138E;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
height: 32px;
padding: 4px 12px;
align-items: center;
gap: 4px;
border-radius: 4px;
border: 0.8px solid #02138E;
background: #FFF;
transition: all 0.3s ease; /* Smooth transition */
}
.plan_status_all button:hover , .subscribe_plan button:hover {
    color: #FFF; /* Change text color on hover */
    background: #02138E; /* Change background color on hover */
    border-color: #FFF; /* Change border color on hover */
  }
  


  /* Product Details css */
  .prod_detail_all{
    border-radius: 12px;
border: 0.8px solid #EFEFEF;
background: #FFF;
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  }
  .prod_detail{
display: flex;
align-items: center;
justify-content: space-between;
  }
  .prod_img{
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .prod_img_des h2{
    margin: 0;
    color: #000;
font-family: "Neue Haas Unica";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
padding-bottom: 6px;
  }
  .prod_img_des p{
    color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
  }
  .prod_valid_all{
height: 26px;
padding: 4px 8px;
align-items: center;
gap: 4px;
flex-shrink: 0;
border-radius: 4px;
background: #F8F8F8;
  }
  .prod_valid h6{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .plan_head_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
  }
  .plan_head h4{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .subscribe_plan button{
    color: #02138E;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
height: 32px;
padding: 4px 12px;
align-items: center;
gap: 4px;
border-radius: 4px;
border: 0.8px solid #02138E;
background: #FFF;
transition: all 0.3s ease; /* Smooth transition */

  }
  .premium_head{
    display: flex;
    align-items: center;
    gap: 40px;
  }
  .premium_head p,.date_valid_head p,.plan_head p{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 0;
margin: 0;
  }
  .premium_head span,.date_valid_head span,.plan_head span{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .switch_plan a,.pause_plan a,.cancel_plan a{
    color: #02138E;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .premium_head_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;

  }
  .date_valid_head,.plan_head{
    display: flex;
    align-items: center;
    gap: 40px;
  }
  .date_valid_all,.plan_type_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;

  }

  .prod_detail_all{
    padding: 20px;
  }



  /* ecom dashboard css */
  .ecom_all{
    padding: 20px;
    border-radius: 12px;
border: 0.8px solid #EFEFEF;
background: #FFF;
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  }
  .create_store_all button{
    color: #02138E;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: inline-flex;
height: 36px;
padding: 4px 12px;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 4px;
border: 0.8px solid #02138E;
background: #FFF;
  }
  .ecom_img{
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .ecom_img_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ecom_img_des h6{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
padding-bottom: 6px;
  }

  .ecom_img_des p{
    color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
padding-bottom: 12px;
margin: 0;
  }
  .store_name{
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .store_name h5{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
  }
  .store_name p{
    color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
margin: 0;
  }
  .store_count span{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: inline-flex;
height: 32px;
padding: 2px 8px;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 4px;
background: #F2F2F2;
  }
  .stores_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list_head p{
    color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .store_details_all{
    padding: 20px;
    border-radius: 12px;
    border: 0.8px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  }
  .company_name h4{
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 4px;
    margin: 0;
  }
  .company_name p{
    color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
margin: 0;
  }
  .company_name_all{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .plan_activate h4,.plan_pricing_all h4{
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
padding-bottom: 4px;
  }
  .plan_activate p,.plan_pricing_all p{
    color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
margin: 0;
  }
  .store_details{
    display: flex;
    align-items: center;
    gap: 40px;
  }
  .plan_activate_all{
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .store_detail_left{
    display: flex;
    align-items: center;
    gap: 40px;
  }
  .store_details{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .store_plan_end span{
    color: #EF2626;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: inline-flex;
height: 26px;
padding: 4px 8px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
background: #FBE9E9;
  }
  .store_plan_status p{
    display: flex;
    align-items: center;
    gap: 4px;
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
  }
  .view_my_store button{
    color: #02138E;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: inline-flex;
height: 36px;
padding: 4px 12px;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 4px;
border: 0.8px solid #02138E;
background: #FFF;
  }
  .store_detail_right{
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .working_des p{
    color: #000;
text-align: center;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 123.077% */
  }
  .working_des h3{
    font-family: Lato;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 133.333% */
background: linear-gradient(90deg, #F10008 0%, #CC2026 70.53%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 16px;
padding-top: 32px;
  }
  .working_on_it{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }


  /* Active Subscription css */

  .active_img_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .active_sub_all{
width: 352px;
padding: 20px;

border-radius: 12px;
border: 0.8px solid #EFEFEF;
background: #FFF;
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  }
  
  .active_img_status p{
    display: flex;
    align-items: center;
    gap: 4px;
    color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
  }
  .sub_des h3{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
padding-top: 16px;
margin: 0;
  }
  .sub_des p{
    color: #666;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
padding-bottom: 6px;
padding-top: 6px;
margin: 0;
  }
  .premium_monthly p{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 181.818% */
margin: 0;
  }
  .premium_monthly h6{
    color: #EF1717;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
margin: 0;
padding-bottom: 32px;
  }
  .view_prod_plan_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .view_prod_plan_all a{
    color: #02138E;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
  }
  .jaga{
    display: flex;
    align-items: center;
    gap: 18px;
  }




/* Overlay for the popup */
.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.342);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.close_btn{
  background: white;
  padding: 20px;
  text-align: center;
  border: none;
}
.close_text_btn{
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 0.8px solid #CACACA;
  color: #fff;
  font-family: "Neue Haas Unica";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: navajowhite;
  background-color: #0000009b;
  align-items: end;
}
.qr_btn{
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 0.8px solid #CACACA;
  color: #fff;
  font-family: "Neue Haas Unica";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: navajowhite;
  background-color: #0000009b;
}

.popup_content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: fit-content;
  height: 80vh;
  overflow: scroll;
}
.search_profile_icons {
  position: relative;
  display: inline-block;
}
.search_profile_icons:hover .myprofile_con {
  display: block;
}

.myprofile_con {
  display: none;
transition: all ease-in-out 0.9s;

}

.myprofile_con{
  width: 320px;
height: fit-content;
flex-shrink: 0;
border-radius: 12px;
border: 0.8px solid #EFEFEF;
background: #FFF;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
padding: 16px;
position: absolute;
top: 32px;
right: 12px;
transition: all ease-in-out 0.9s;

}
.my_pro_img{
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 12px;
  padding: 10px;
}
.my_pro_text p:nth-child(1){
  color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 0;
margin: 0;
padding-bottom: 4px;
}
.my_pro_text p:nth-child(2){
  color: #888;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 0;
margin: 0;
padding-bottom: 4px;

}
.my_pro_text a{
  color: #02138E;
  font-family: "Neue Haas Unica";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.active_items ul{
padding: 0;
margin: 0;
}
.active_items ul li a{
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.my_ac_btns{
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.my_ac_btns a:nth-child(1){
  display: flex;
width: 140px;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 0.6px solid #122EF4;
background: #02138E;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
color: #FFF;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
white-space: nowrap;
}
.my_ac_btns button{
  display: flex;
width: 140px;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 0.6px solid #F5B2B2;
background: #FFEAEA;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
color: #EF1717;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}
.myprofile_account_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
}
.profile_btn{
  display: flex;
  gap: 8px;
}
.myprofile_account_mini{
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.profile_btn button:hover{
border: 0.6px solid #fff;
}
.profile_btn button{
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
border: 0.6px solid #D5D5D5;
background: #FFF;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
color: #000;
font-family: "Neue Haas Unica";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}


.form_con_my_pro label{
  color: #999;
  font-family: "Neue Haas Unica";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 100% */
  margin-bottom: 4px;
  margin-top: 24px;
}
.form_con_my_pro input{
  display: flex;
width: 100%;
height: 40px;
padding: 8px 16px;
align-items: center;
gap: 8px;
color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 15px; /* 100% */
border-radius: 8px;
background: #F2F2F2;
border: none;
}
.form_con_my_pro button{
  display: flex;
width: 80px;
height: 40px;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 0.6px solid #D5D5D5;
background: #FFF;
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
color: #000;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 15px; /* 100% */
}
.form_con_my_pro p{
  color: #000;
  font-family: "Neue Haas Unica";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 100% */
  margin-top: 8px;
  margin: 0;
  padding: 0;
  align-self: baseline; 
}
  