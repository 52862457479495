@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.poppins-semibold {
    font-weight: 600;
    font-style: normal;
}

.poppins-medium {
    font-weight: 500;
    font-style: normal;
}

.poppins-regular {
    font-weight: 400;
    font-style: normal;
}

.poppins-light {
    font-weight: 300;
    font-style: normal;
}

h1, p , h2, h3 ,ul,li{
    font-family: 'Poppins', sans-serif;
}

body {
    font-family: "poppins", sans-serif;
    --main-hue: 208;
    --main-color: hsl(var(--main-hue), 92%, 54%);
    --main-color-hover: hsl(var(--main-hue), 77%, 48%);
    --text-color: #000;
    --bg-color: #fff;
    --light-text-color: #9ca7b6;
    --input-bg: hsla(var(--main-hue), 50%, 50%, 6.5%);
    --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
    --input-text: #8c9aaf;
    --light-main-color: hsl(var(--main-hue), 91%, 55%);
}

.contact {
    width: 100%;
    overflow: hidden;
}

.contact.container-contact {
    height: 100vh;
    min-height: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contact.container-contact {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}



.contact-heading h1 {
    font-weight: 600;
    color: var(--text-color);
    font-size: 3rem;
    line-height: 0.9;
    white-space: nowrap;
    margin-bottom: 1.2rem;
}

.text {
    color: var(--light-text-color);
    line-height: 1rem;
    font-size: 1rem;
}

.text a {
    color: var(--main-color);
    transition: 0.3s;
}

.text a:hover {
    color: var(--main-color-hover);
}

.form-wrapper {
    max-width: 32rem;
}

.contact-form {
    /* display: grid; */
    margin-top: 2.55rem;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;
}

.contact-form .input-wrap:nth-child(3) {
    grid-column: span 2;
}

.contact-form .input-wrap:nth-child(4) {
    grid-column: span 2;
}

.contact-form .input-wrap:nth-child(5) {
    grid-column: span 2;
}

.contact-buttons {
    margin-left: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 40px;
    color: white;
    background-color: #007E60;
    border: none;
}

.input-wrap.w-100 {
    grid-column: span 2;
}

.contact-input {
    width: 100%;
    background-color: var(--input-bg);
    border: none;
    outline: none;
    padding: 1.5rem 1.3rem calc(0.75rem - 2px) 1.35rem;
    font-family: inherit;
    border-radius: 20px;
    color: var(--text-color);
    font-weight: 600;
    font-size: 0.95rem;
    transition: 0.3s;
    border: 2px solid transparent;
    box-shadow: 0 0 0 0px hsla(var(--main-hue), 92%, 54%, 0.16%);
}

.contact-input:hover {
    background-color: var(--input-bg-hover);
}

.input-wrap label {
    position: absolute;
    top: 50%;
    left: calc(1.35rem + 2px);
    transform: translateY(-50%);
    color: var(--light-text-color);
    pointer-events: none;
    transition: 0.25s;
}

.input-wrap {
    position: relative;
}

.input-wrap .icon {
    position: absolute;
    right: calc(1.35rem + 2px);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--input-text);
    font-size: 1.2rem;
    transition: 0.3s;
}

textarea.contact-input {
    resize: none;
    width: 100%;
    min-height: 150px;
}

textarea.contact-input ~ label {
    top: 1.2rem;
    transform: none;
}

textarea.contact-input ~ .icon {
    top: 1.3rem;
    transform: none;
}

.input-wrap.focus .contact-input {
    background-color: var(--bg-color);
    border: 2px solid var(--light-main-color);
    box-shadow: 0 0 0 5px hsla(var(--main-hue), 91%, 55%, 0.11%);
}

.input-wrap.focus label {
    color: var(--light-main-color);
}

.input-wrap.focus .icon {
    color: var(--light-main-color);
}

.input-wrap.not-empty label {
    font-size: .66rem;
    top: 0.75rem;
    transform: translateY(0);
}

.contact-button {
    border-radius: 200px;
}

/* .right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    pointer-events: none;
    width: 1500px;
    padding-left: 150px;
} */

.info-wrapper {
    position: relative;
    grid-column: 2;
    font-family: "poppins", sans-serif;
}

.info-wrapper h1 {
    text-align: center;
}

.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    gap: 80px;
    margin-left: 90px;
    margin-top: 60px;
    color: #8c9aaf;
}

.icons i {
    font-size: 3rem;
    color: #8c9aaf;
}

.info-wrapper  .i {
    font-size: 25px;
    color: #007E60;
    margin-top: 40px;
    margin-left: 20px;
}

.info-wrapper p {
    font-size: 1.2rem;
    line-height: 1.5;
    font-family: "poppins", sans-serif;
}

.conatct-details {
    display: flex !important;
    align-items: baseline;
}


/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .contact.container-contact {
        grid-template-columns: 1fr;
    }


    .icons {
        width: 50%;
        gap: 40px;
        margin-left: 45px;
    }
}

@media (max-width: 768px) {
    .contact.container-contact {
        grid-template-columns: 1fr;
        min-height: auto;
    }

    .left {
        padding: 20px 1rem 0;
    }

    .contact-heading h1 {
        font-size: 2.5rem;
    }

    .text {
        font-size: 0.9rem;
    }

    .form-wrapper {
        max-width: 100%;
    }

    .contact-buttons {
        margin-left: 0;
        width: 100%;
        text-align: center;
    }

    .icons {
        width: 70%;
        gap: 20px;
        margin-left: 25px;
    }

    .info-wrapper p {
        font-size: 1rem;
    }

    .info-wrapper i {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .contact.container-contact {
        grid-template-columns: 1fr;
        min-height: auto;
    }

    .left {
        padding: 10px 0.5rem 0;
    }

    .contact-heading h1 {
        font-size: 2rem;
    }

    .text {
        font-size: 0.8rem;
    }

    .form-wrapper {
        max-width: 100%;
    }

    .contact-buttons {
        margin-left: 0;
        width: 100%;
        text-align: center;
    }

    .icons {
        width: 90%;
        gap: 10px;
        margin-left: 15px;
    }

    .info-wrapper p {
        font-size: 0.9rem;
    }

    .info-wrapper i {
        font-size: 18px;
    }
    .contact-buttons{
        margin-left: 95px;
    }
}
