@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.poppins-semibold {
    font-weight: 600;
    font-style: normal;
}

.poppins-medium {
    font-weight: 500;
    font-style: normal;
}

.poppins-regular {
    font-weight: 400;
    font-style: normal;
}

.poppins-light {
    font-weight: 300;
    font-style: normal;
}

h1, p , h2, h3 ,ul,li{
    font-family: 'Poppins', sans-serif;
}

/* .container {
    width: 80%;
    margin: 0 auto;
} */

.con-2 {
    margin: 50px 0px;
    padding-bottom: 0px;
    padding-top: 20px;
    padding-right: 40px;
    border-top: 5px solid #007E60;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
}

.content {
    z-index: 1;
    padding: 10px;
}

.ul-content li {
    position: relative;
    animation: slideLeft 1s linear;
}

@keyframes slideLeft {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.content p {
    background-color: white;
    margin: 0;
    border-radius: 20px;
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
    padding: 30px;
}

.content-con {
    margin: 0;
    border-radius: 20px;
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
    padding: 30px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.418);
}

.content-con {
    font-size: 20px;
    line-height: 1.6;
}

.content-con img {
    position: relative;  
    border-radius: 20px;
}

.buisness {
    font-size: 20px;
    line-height: 1.6;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.b-content {
    margin-top: 50px;
    width: 50%;
}

.b-content h1 {
    z-index: 99;
    position: relative;
    display: inline-block;
    transition: transform 0.5s, color 0.5s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.heading {
    color: black;
    padding: 15px;
    cursor: pointer;
    margin: 10px 0;
    margin-bottom: 80px;
    text-align: center;
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    border: 2px solid transparent;
    font-size: 20px;
    background-color: white;
    white-space: nowrap;
}

.heading-about {
    
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.051)), url("https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/pattern-bg.png") !important;

    height: 300px;
    width: 100%;
    margin-bottom: 50px;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.heading-about h1 {
    font-size: 40px;
}

.heading-about p {
    font-size: 20px;
}

.heading:hover {
    background-color: #e1e5ea;
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.content {
    display: none;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    border-radius: 15px;
    animation: slideIn 0.7s forwards;
    
}

.content.default {
    display: block;
}

.selected {
    border: 5px solid #007E60;
    border-color: #19875436;
    transform: translateY(-10px);
    box-shadow: 5px 5px 10px #007E60;
}

.headings {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    cursor: pointer;
    animation: jump 2s infinite;
}

@keyframes jump {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

.selecteds {
    color: #007E60;
    text-shadow: 5px 10px 50px #007E60;
}

.content p {
    animation: slideOut 0.7s forwards;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.second-con {
    background: linear-gradient(
        rgba(0, 0, 0, 0.5), 
        rgba(0, 0, 0, 0.649)
    ), url("https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/analytics.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}

.second-con h2 {
    color: white;
    padding: 30px;
    padding-top: 60px;
}

.second-con p {
    margin-top: 80px;
    background: none;
    color: white;
}

.partner-logo {
    color: #007E60;
}

.heading-about p {
    font-size: 16px;
    width: 50%;
}

.greencon {
    background-color: #007E60 !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    height: 300px;
    margin-bottom: 50px;
}

.greencon h1 {
    color: white;
    padding-bottom: 20px;
}

.greencon ul li {
    padding-bottom: 10px;
    font-size: 20px;
}

/* Media Queries for Responsive Design */

@media (max-width: 1200px) {
    /* .container {
        width: 90%;
    } */

    .b-content {
        width: 70%;
    }

    .icons {
        width: 50%;
        gap: 40px;
        margin-left: 45px;
    }
}

@media (max-width: 768px) {
    .container {
        width: 100%;
    }

    .heading-about {
        text-align: center;
        padding: 20px;
    }

    .heading-about h1 {
        font-size: 30px;
    }

    .heading-about p {
        font-size: 16px;
        width: 100%;
    }

    .content p {
        padding: 20px;
    }

    .content-con {
        font-size: 18px;
        padding: 20px;
    }

    .b-content {
        width: 90%;
    }

    .heading {
        font-size: 18px;
    }

    .icons {
        width: 70%;
        gap: 20px;
        margin-left: 25px;
    }

    .second-con h2 {
        padding: 20px;
        padding-top: 40px;
    }

    .second-con p {
        margin-top: 60px;
    }

    .greencon {
        flex-direction: column;
        height: auto;
        padding: 20px;
    }

    .greencon ul li {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .container {
        width: 100%;
        padding: 0 10px;
    }
    .headings {
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        cursor: pointer;
        animation: jump 2s infinite;
        margin-left: 10px;
        white-space: nowrap;
    }

    .heading-about {
        height: 200px;
        text-align: center;
        padding: 10px;
    }

    .heading-about h1 {
        font-size: 24px;
    }

    .heading-about p {
        font-size: 14px;
    }

    .content p {
        padding: 10px;
    }

    .content-con {
        font-size: 16px;
    }

    .b-content {
        width: 100%;
    }

    .heading {
        font-size: 16px;
    }

    .icons {
        width: 90%;
        gap: 10px;
        margin-left: 15px;
    }

    .second-con h2 {
        padding: 10px;
        padding-top: 20px;
    }

    .second-con p {
        margin-top: 40px;
    }

    .greencon {
        flex-direction: column;
        height: auto;
        padding: 10px;
    }

    .greencon ul li {
        font-size: 16px;
    }
}
