/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;600;700;800;900&family=Quicksand:wght@300;400;500;600;700&display=swap'); */

:root{
    --Playfair: 'Playfair Display', serif;
    --Quicksand: 'Quicksand', sans-serif;
    --Roboto: 'Roboto', sans-serif;
    --dark: #3c393d;
    --exDark: #2b2b2b;
}
*{
    padding: 0;
    margin: 0;
    font-family: var(--Quicksand);
}
body{
    line-height: 1.4;
    color: var(--dark);
}
.blog-all img{
    width: 100%;
    display: block;
}
.blog-all .container{
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 1.2rem;
}

/* header */
.blog-all header{
    min-height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/banner-bg.webp') center/cover no-repeat fixed;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}


.blog-all .banner{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
}
.blog-all .banner-title{
    font-size: 3rem;
    font-family: var(--Playfair);
    line-height: 1.2;
}
.blog-all .banner-title span{
    font-family: var(--Playfair);
    color: var(--exDark);
}
.blog-all .banner p{
    padding: 1rem 0 2rem 0;
    font-size: 1.2rem;
    text-transform: capitalize;
    font-family: var(--Roboto);
    font-weight: 300;
    word-spacing: 2px;
    color:white !important ;
}
.blog-all .banner form{
    background: #fff;
    border-radius: 2rem;
    padding: 0.6rem 1rem;
    display: flex;
    justify-content: space-between;
}
.blog-all .search-input{
    font-family: var(--Roboto);
    font-size: 1.1rem;
    width: 100%;
    outline: 0;
    padding: 0.6rem 0;
    border: none;
}
.blog-all .search-input::placeholder{
    text-transform: capitalize;
}
.blog-all .search-btn{
    width: 40px;
    font-size: 1.1rem;
    color: var(--dark);
    border: none;
    background: transparent;
    outline: 0;
    cursor: pointer;
}

/* design */
.blog-all .design{
    padding: 4.5rem 0;
}
.blog-all .title{
    text-align: center;
    padding: 1rem 0;
}
.blog-all .title h2{
    font-family: var(--Playfair);
    font-size: 2.4rem;
}
.blog-all .title p{
    text-transform: uppercase;
    padding: 0.6rem 0;
}
.blog-all .design-content{
    margin: 2rem 0;
}
.blog-all .design-item{
    cursor: pointer;
    margin: 1.5rem 0;
}
.blog-all .design-img{
    position: relative;
    overflow: hidden;
}
.blog-all .design-img::after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}
.blog-all .design-img img{
    transition:  all 0.5s ease;
}
.blog-all .design-item:hover img{
    transform: scale(1.2);
}
.blog-all .design-img span:first-of-type{
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
    background: var(--exDark);
    color: #fff;
    padding: 0.25rem 1rem;
}
.blog-all .design-img span:last-of-type{
    position: absolute;
    z-index: 1;
    bottom: 10px;
    right: 10px;
    color: #fff;
    font-weight: 700;
    font-size: 1.1rem;
}
.blog-all .design-title{
    padding: 1rem;
    font-size: 1.2rem;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
.blog-all .design-title a{
    color: var(--dark);
    text-decoration: none;
    text-transform: capitalize;
    font-family: var(--Playfair);
}

/* blog */
 .blog{
    background: #f9f9f9;
    /* padding: 4.5rem 0; */
}
.blog-all .blog-content{
    margin: 2rem 0;
}
 .blog-img{
    position: relative;
}
 .blog-img span{
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: var(--exDark);
    color: #fff;
    font-size: 1.4rem;
    padding: 0.3rem 0.6rem;
}
 .blog-text{
    margin: 2.2rem 0;
    padding: 0 1rem;
}
 .blog-text span{
    font-weight: 300;
    display: block;
    padding-bottom: 0.5rem;
}
.blog-text h2{
    font-family: var(--Playfair);
    padding: 1rem 0;
    font-size: 1.65rem;
    font-weight: 500;
}
 .blog-text p{
    font-weight: 300;
    font-size: 1.1rem;
    opacity: 0.9;
    padding-bottom: 1.2rem;
}
 .blog-text a{
    font-family: var(--Roboto);
    font-size: 1.1rem;
    text-decoration: none;
    color: var(--dark);
    display: inline-block;
    background: var(--dark);
    color: #fff;
    padding: 0.55rem 1.2rem;
    transition: all 0.5s ease;
}
 .blog-text a:hover{
    background: var(--exDark);
}

/* about */
.blog-all .about{
    padding: 4.5rem 0;
}
.blog-all .about-text{
    margin: 2rem 0;
}
.blog-all .about-text > p{
    font-size: 1.1rem;
    padding: 0.6rem 0;
    opacity: 0.8;
}



/* Media Queries */
@media screen and (min-width: 540px){
    
    .blog-all.banner-title{
        font-size: 5rem;
    }
    .blog-all.banner form{
        margin-top: 1.4rem;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 768px){
    /*.blog-all .container{*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    justify-content: space-between;*/
    /*}*/
    .blog-all.search-input{
        padding: 0.8rem 0;
    }
    .blog-all .design-content{
        /* display: grid; */
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
    .blog-all.design-item{
        margin: 0;
    }
    .blog-all.blog-content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}

@media screen and (min-width: 992px){
    .blog-all.blog-content{
        grid-template-columns: repeat(3, 1fr);
    }
    .blog-all.about-content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3rem;
        align-items: center;
    }
}

@media screen and (min-width: 1200px){
    .blog-all .design-content{
        grid-template-columns: repeat(3, 1fr);
    }
}


.header-blog-img{
    background-image: url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/art-design-5.webp);
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
}
.blog-share{
    display: flex;
    gap: 25px !important;
}
.tag-des i{
color: #040e56;
font-size: 35px;
}
.tag-des p{
    font-size: 20px;
}
.tag-des-cat{
    display: flex;
    gap: 25px !important;

}
.tag-des-cat p{
    border: 2px solid #dfdced;
    padding: 10px !important;
    color: #616899;
    border-radius: 10px !important;
}
.blog-head{
    align-items: center;

}
.tag-des-cat p:hover {
    color: white !important;
    background-color: #616899 !important;
}
.blog-head h2{
    color: #040e56;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.ban-des1 h2{
    color: #040e56;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.about-text-blog{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center !important;
    height: 100%;
}

.learnmore-blog {
    background-color: #040e56; /* Blue background color */
    color: white; /* White text color */
    border: none; /* No border */
    padding: 10px 20px; /* Padding around the button */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    margin-top: 7px;
}

.learnmore-blog:hover {
    background-color: #040e56; /* Darker blue on hover */
    color: white;
}
