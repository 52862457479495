@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.poppins-semibold {
    font-weight: 600;
    font-style: normal;
}

.poppins-medium {
    font-weight: 500;
    font-style: normal;
}

.poppins-regular {
    font-weight: 400;
    font-style: normal;
}

.poppins-light {
    font-weight: 300;
    font-style: normal;
}

h1, p , h2, h3 ,ul,li{
    font-family: 'Poppins', sans-serif;
}

body {
    font-family: 'Poppins', sans-serif;
}

.maincan {
    background-image: url("https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/network-connections-on-white-background-vector.jpg");
    background-position: fixed;
}



.con-2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 20px;
    padding-right: 40px;
    border-right: 5px solid #007E60;
    margin-right: 10px;
}

.content {
    z-index: 1;
    background: #007E60;
}

.ul-content li {
    position: relative;
    animation: slideLeft 1s linear;
}

.title-effect {
    z-index: 99;
    position: relative;
    display: inline-block;
    transition: transform 0.5s, color 0.5s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

@keyframes slideLeft {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.content p {
    background-color: white;
    margin: 0;
    border-radius: 20px;
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
    padding: 30px;
}

.content-con h3 {
    font-size: 20px;
}

.content-con {
    margin: 0;
    border-radius: 20px;
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
    padding: 20px;
}

.content-con {
    font-size: 14px;
}

.content-con img {
    position: relative;
    border-radius: 20px;
}

.buisness {
    font-size: 20px;
    line-height: 1.6;
    width: 100%;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
}

.b-content {
    margin-top: 50px;
    width: 50%;
}

.heading {
    color: black;
    padding: 15px;
    cursor: pointer;
    margin: 10px 0;
    margin-bottom: 80px;
    text-align: center;
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    border: 2px solid transparent;
    font-size: 20px;
    box-shadow: 5px 5px 10px #00000093;
    background-color: white;
    white-space: nowrap;
}

.heading-about {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.051)), url(https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/pattern-bg.png) !important;
    height: 300px;
    width: 100%;
    margin-bottom: 50px;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 90px;
}

.heading-about h1 {
    font-size: 40px;
}

.heading-about p {
    font-size: 20px;
}

.heading:hover {
    background-color: #e1e5ea;
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.content {
    display: none;
    padding: 35px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    border-radius: 15px;
    animation: slideIn 0.7s forwards;
}

.content.default {
    display: block;
}

.selected {
    border: 5px solid #007E60;
    border-color: #19875436;
    transform: translateY(-10px);
    box-shadow: 5px 5px 10px #007E60;
}

.our-products {
    display: flex;
}

.content p {
    animation: slideOut 0.7s forwards;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (max-width: 1200px) {
    

    .con-2 {
        padding-right: 20px;
        margin-right: 5px;
    }

    .b-content {
        width: 70%;
    }

    .our-products {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .container {
        width: 100%;
    }

    .con-2 {
        padding-right: 10px;
        margin-right: 0;
        flex-direction: row;
    }

    .heading-about {
        padding: 20px;
        text-align: center;
    }

    .heading-about h1 {
        font-size: 30px;
    }

    .heading-about p {
        font-size: 11px;
    }

    .content p {
        padding: 20px;
    }

    .content-con {
        font-size: 18px;
    }

    .b-content {
        width: 90%;
    }

    .heading {
        font-size: 18px;
        padding: 10px 5px!important;
        justify-content: space-around;
        margin-left: 10px;
        border-left: 0px;
        display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 20px;
    padding-right: 40px;
    border-right:none !important;
    }

    .our-products {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 480px) {
    .container {
        width: 100%;
        padding: 0 10px;
    }

    .con-2 {
        padding-right: 0;
        margin-right: 0;
    }

    .heading-about {
        height: 200px;
        padding: 10px;
        text-align: center;
    }

    .heading-about h1 {
        font-size: 24px;
    }

    .heading-about p {
        font-size: 14px;
    }

    .content p {
        padding: 10px;
    }

    .content-con {
        font-size: 16px;
    }

    .b-content {
        width: 100%;
    }

    .heading {
        font-size: 16px;
    }

    .our-products {
        flex-direction: column;
        align-items: center;
    }
}
