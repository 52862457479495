@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.registration {
    padding-top: 143px;
    padding-bottom: 20px;
}

.reg-form img,
.welcome-back img,
.otp-form img {
    width: 160px;
    height: 32px;
    margin-bottom: 20px;
}

.reg-form label,
.welcome-back label,
.otp-form label {
    padding-bottom: 4px;
}

.reg-form h2,
.welcome-back h2,
.otp-form h2 {
    color: #040E56;
    font-family: Lato;
    font-size: 29.623px;
    font-style: normal;
    font-weight: 900;
    line-height: 38.88px;
    /* 131.25% */
    text-align: center;
    padding-bottom: 40px;
}

.reg-form input,
.welcome-back input,
.otp-form input {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 20px;
    align-items: center;
    border-radius: 7.406px;
    border: 0.926px solid #B6B6B6;
    background: var(--Primary-Base-White, #FFF);
    margin-bottom: 20px;
}

.reg-form button,
.otp-form button {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 18.514px 22.217px;
    justify-content: center;
    align-items: center;
    gap: 9.257px;
    flex-shrink: 0;
    border-radius: 7.406px;
    background: #040E56;
    color: var(--Primary-Base-White, #FFF);
    font-family: "Neue Haas Unica";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 100% */
    border: none !important;
}

.reg-form p {
    color: #666;
    font-family: "Neue Haas Unica";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.886px;
    /* 126.234% */
}

.reg-form-all,
.welcome-back,
.otp-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.reg-form h5 {
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.811px;
    /* 98.743% */
}

.reg-form a {
    color: #040E56;
    font-family: "Neue Haas Unica";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.663px;
    /* 98.017% */
}

.form-img {
    background-image: url("../pages/images/frame.png");
    height: 780px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.form-img-des {
    padding-top: 145px;

}

.form-img-des h2 {
    color: var(--Primary-Base-White, #FFF);
    font-family: Lato;
    font-size: 29.623px;
    font-style: normal;
    font-weight: 900;
    line-height: 38.88px;
    /* 131.25% */
    padding-bottom: 14.81px;
    text-align: center;
}

.form-img-des p {
    color: var(--Primary-Base-White, #FFF);
    font-family: "Neue Haas Unica";
    font-size: 15.737px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.069px;
    /* 152.941% */
    width: 500.594px;
    text-align: start;
    padding-left: 55px;
}

.otp_sbt_btn {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 18.514px 22.217px;
    justify-content: center;
    align-items: center;
    gap: 9.257px;
    flex-shrink: 0;
    border-radius: 7.406px;
    background: #040E56;
    color: var(--Primary-Base-White, #FFF);
    font-family: "Neue Haas Unica";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 100% */
    border: none !important;
}

.reg-form p {
    color: #666;
    font-family: "Neue Haas Unica";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.886px;
    /* 126.234% */
}

.reg-form-all,
.welcome-back,
.otp-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.reg-form h5 {
    color: #000;
    font-family: "Neue Haas Unica";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.811px;
    /* 98.743% */
}

.reg-form a {
    color: #040E56;
    font-family: "Neue Haas Unica";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.663px;
    /* 98.017% */
}

.form-img {
    background-image: url("../pages/images/frame.png");
    height: 780px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.form-img-des {
    padding-top: 145px;

}

.form-img-des h2 {
    color: var(--Primary-Base-White, #FFF);
    font-family: Lato;
    font-size: 29.623px;
    font-style: normal;
    font-weight: 900;
    line-height: 38.88px;
    /* 131.25% */
    padding-bottom: 14.81px;
    text-align: center;
}

.form-img-des p {
    color: var(--Primary-Base-White, #FFF);
    font-family: "Neue Haas Unica";
    font-size: 15.737px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.069px;
    /* 152.941% */
    width: 500.594px;
    text-align: start;
    padding-left: 55px;
}
.password_view{
    position: absolute;
    top: 50%;
    right: 20px;
    cursor: pointer;
}
.forgot_pass {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.forgot_pass input {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #B6B6B6;
    margin: 0;
}
.forgot_pass label{
    color: #000;
font-family: "Neue Haas Unica";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 14px; /* 100% */
}
.forgot_pass a{
    color: #040E56;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 15px; /* 100% */
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}


@media screen and (max-width: 1000px) {
    .form-img {
        height: 100% !important;
    }

    .otp-btn {
        width: 100% !important;
    }

    .form-img-des p {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .otp-btn {
        width: 280px !important;
    }

    .form-img-des p {
        width: 100%;
    }

    .reg-form input,
    .welcome-back input,
    .otp-form input {
        width: 100%;
    }

    .welcome-back,
    .reg-form {
        border-radius: 0 !important;
        border-top: 0 !important;
        border-bottom: 0 !important;
        border-left: 0 !important;
        background: var(--Primary-Base-White, #FFF);
    }

    .reg-form h2,
    .welcome-back h2,
    .otp-form h2 {
        padding-top: 50px;
    }
}


.account-creation {
    padding-top: 58px;
}

.otp-btn {
    color: #040E56 !important;
    border-radius: 7.406px !important;
    border: 0.926px solid #040E56 !important;
    background: var(--Primary-Base-White, #FFF);
    background-color: #FFF !important;
    font-family: "Neue Haas Unica";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 100% */
    display: flex;
    width: 420px;
    height: 48px;
    padding: 18.514px 22.217px;
    justify-content: center;
    align-items: center;
    gap: 9.257px;
}

.loginn {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 18.514px 22.217px;
    justify-content: center;
    align-items: center;
    gap: 9.257px;
    border-radius: 7.406px;
    background: #040E56 !important;
    color: var(--Primary-Base-White, #FFF);
    font-family: "Neue Haas Unica";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 100% */
    margin-bottom: 20px;
}

.sign-up-sec h5 {
    color: #000;
    padding-top: 100px;
    font-family: "Neue Haas Unica";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.811px;
    /* 98.743% */
}

.sign-up-sec a {
    color: #040E56;
    font-family: "Neue Haas Unica";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.663px;
    /* 98.017% */
    text-decoration-line: underline;

}

.otp-creation h5 {
    color: #000;
    padding-top: 100px;
    font-family: "Neue Haas Unica";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.811px;
    /* 98.743% */
}

.otp-creation a {
    color: #040E56;
    font-family: "Neue Haas Unica";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.663px;
    /* 98.017% */
    text-decoration-line: underline;

}

.welcome-back,
.reg-form {
    border-radius: 32px 0px 0px 32px;
    border-top: 5.554px solid #F8F7FC;
    border-bottom: 5.554px solid #F8F7FC;
    border-left: 5.554px solid #F8F7FC;
    background: var(--Primary-Base-White, #FFF);
}

.welcome_all {
    padding-top: 90px;
}